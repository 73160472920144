@import "../base";

/** @define Inlay */

.Inlay {
  margin-bottom: 5rem;
  background-color: rgb(var(--color-gray-light));
}

.Inlay-content {
  position: relative;
  margin-top: -3.5rem;
}

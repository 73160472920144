/* @define utilities */

@import "../global";

/**
 * All
 */

 .u-spaceA0 {
   margin: 0 !important;
 }

.u-spaceA1 {
  margin: 0.5rem !important;
}

.u-spaceA2 {
  margin: 1rem !important;
}

.u-spaceA3 {
  margin: 1.5rem !important;
}

.u-spaceA4 {
  margin: 2rem !important;
}

.u-spaceA5 {
  margin: 2.5rem !important;
}

.u-spaceA6 {
  margin: 3rem !important;
}

.u-spaceA8 {
  margin: 4rem !important;
}

/**
 * Top
 */

.u-spaceT0 {
  margin-top: 0 !important;
}

.u-spaceT1 {
  margin-top: 0.5rem !important;
}

.u-spaceT2 {
  margin-top: 1rem !important;
}

.u-spaceT3 {
  margin-top: 1.5rem !important;
}

.u-spaceT4 {
  margin-top: 2rem !important;
}

.u-spaceT5 {
  margin-top: 2.5rem !important;
}

.u-spaceT6 {
  margin-top: 3rem !important;
}

.u-spaceT8 {
  margin-top: 4rem !important;
}

/**
 * Right
 */

.u-spaceR0 {
  margin-right: 0 !important;
}

.u-spaceR1 {
  margin-right: 0.5rem !important;
}

.u-spaceR2 {
  margin-right: 1rem !important;
}

.u-spaceR3 {
  margin-right: 1.5rem !important;
}

.u-spaceR4 {
  margin-right: 2rem !important;
}

.u-spaceR5 {
  margin-right: 2.5rem !important;
}

.u-spaceR6 {
  margin-right: 3rem !important;
}

.u-spaceR8 {
  margin-right: 4rem !important;
}

/**
 * Bottom
 */

.u-spaceB0 {
  margin-bottom: 0 !important;
}

.u-spaceB1 {
  margin-bottom: 0.5rem !important;
}

.u-spaceB2 {
  margin-bottom: 1rem !important;
}

.u-spaceB3 {
  margin-bottom: 1.5rem !important;
}

.u-spaceB4 {
  margin-bottom: 2rem !important;
}

.u-spaceB5 {
  margin-bottom: 2.5rem !important;
}

.u-spaceB6 {
  margin-bottom: 3rem !important;
}

.u-spaceB8 {
  margin-bottom: 4rem !important;
}

/**
 * Left
 */

 .u-spaceL0 {
  margin-left: 0 !important;
}

.u-spaceL1 {
  margin-left: 0.5rem !important;
}

.u-spaceL2 {
  margin-left: 1rem !important;
}

.u-spaceL3 {
  margin-left: 1.5rem !important;
}

.u-spaceL4 {
  margin-left: 2rem !important;
}

.u-spaceL5 {
  margin-left: 2.5rem !important;
}

.u-spaceL6 {
  margin-left: 3rem !important;
}

.u-spaceL8 {
  margin-left: 4rem !important;
}

/**
 * Vertical
 */

.u-spaceV0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.u-spaceV1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.u-spaceV2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.u-spaceV3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.u-spaceV4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.u-spaceV5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.u-spaceV6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.u-spaceV8 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

/**
 * Horizontal
 */

.u-spaceH0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.u-spaceH1 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.u-spaceH2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.u-spaceH3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.u-spaceH4 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.u-spaceH5 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.u-spaceH6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.u-spaceH8 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

/* @define Intersection */

@import "../base";

.Intersection {
  max-width: 17em;
  font-size: 2rem;
  line-height: 1.15;
  font-weight: 600;
  hyphens: auto;
  text-transform: uppercase;
  font-family: var(--heading-font-family);
  word-spacing: var(--heading-word-spacing);
  text-align: left;
}

@media (min-width: 350px) {
  .Intersection {
    font-size: 2.5rem;
  }
}

@media print {
  .Intersection {
    display: none !important;
  }
}

.Intersection-title {
  color: rgb(148, 148, 148);
}

.Intersection a {
  background: rgb(var(--color-link));
  color: rgb(var(--current-background));
  padding: 0.14em 0.14em 0.07em;
  margin: 0;
  white-space: nowrap;
  font-weight: normal;
  transition: background-color 140ms var(--ease-out);
  hyphens: none;
}

.Intersection a:hover {
  background: rgb(var(--color-link-shaded));
  color: #fff;
}

.Intersection--restrained {
  font-size: 1.7rem;
  margin: -2rem 0;
}

@media (min-width: 800px) {
  .Intersection--restrained {
    font-size: 2rem;
  }
}

@media (min-width: 900px) {
  .Intersection--restrained {
    font-size: 1.5rem;
  }
}

@media (min-width: 1000px) {
  .Intersection {
    font-size: 4rem;
  }

  .Intersection--restrained {
    font-size: 1.55rem;
  }
}

@media (min-width: 1100px) {
  .Intersection--restrained {
    font-size: 1.9rem;
  }
}

@media (min-width: 1400px) {
  .Intersection--restrained {
    font-size: 2.2rem;
  }
}

@media (min-width: 2000px) {
  .Intersection--restrained {
    font-size: 2.4rem;
  }
}

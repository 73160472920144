:root {
  /* Base */

  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --default-font-size: 1rem;
  --default-line-height: 1.5;

  --heading-font-family: "Giorgio Sans Bold", "AvenirNextCondensed-DemiBold", "HelveticaNeue-CondensedBold", "Helvetica Inserat", "Franklin Gothic Condensed", Haettenschweiler, Impact, sans-serif-condensed, sans-serif;
  --heading-word-spacing: 0.04em;
  --heading-line-height: 1.2;

  --default-background-color: 255, 255, 255;
  --default-color: 26, 26, 26;

  --color-link: var(--color-theme, 38, 189, 226);
  --color-link-shaded: var(--color-theme-shaded, 16, 162, 198);

  --color-text-muted: 117, 117, 117;
  --color-text-muted-shaded: 80, 80, 80;

  --max-width: 2000px;
  --page-gutter: 1.125rem;

  --focus-ring-width: 0.2rem;
  --focus-ring-color: var(--color-gray-dark);

  --figure-gradient: linear-gradient(
    -180deg,
    rgba(0, 0, 0, 0.08) 0%,
    rgba(0, 0, 0, 0.10) 30%,
    rgba(0, 0, 0, 0.11) 55%,
    rgba(0, 0, 0, 0.7) 100%
  );

  /* Easing curves */

  --ease-in: cubic-bezier(0.4, 0, 1, 1);
  --ease-out: cubic-bezier(0, 0, 0.2, 1);
  --ease: cubic-bezier(0.4, 0, 0.2, 1);

  /* For use with "theme" utilities */

  --current-background: var(--default-background-color);
  --current-color: var(--default-color);

  /** Grays */

  --color-gray-light: 241, 241, 241;
  --color-gray: 170, 170, 170;
  --color-gray-dark: 26, 26, 26;

  /* Third-party colors */

  --color-twitter: 29, 161, 242;
  --color-facebook: 60, 90, 153;
  --color-youtube: 218, 34, 25;
  --color-instagram: 35, 35, 35;

  /* Goal colors */

  --color-goal-1: 229, 36, 59;
  --color-goal-1-shaded: 117, 0, 31;
  --color-goal-2: 221, 166, 58;
  --color-goal-2-shaded: 119, 72, 4;
  --color-goal-3: 76, 159, 56;
  --color-goal-3-shaded: 13, 59, 6;
  --color-goal-4: 197, 25, 45;
  --color-goal-4-shaded: 91, 1, 4;
  --color-goal-5: 255, 58, 33;
  --color-goal-5-shaded: 128, 5, 1;
  --color-goal-6: 38, 189, 226;
  --color-goal-6-shaded: 1, 83, 121;
  --color-goal-7: 252, 195, 11;
  --color-goal-7-shaded: 123, 74, 0;
  --color-goal-8: 162, 25, 66;
  --color-goal-8-shaded: 64, 0, 42;
  --color-goal-9: 253, 105, 37;
  --color-goal-9-shaded: 135, 38, 0;
  --color-goal-10: 221, 19, 103;
  --color-goal-10-shaded: 110, 0, 53;
  --color-goal-11: 253, 157, 36;
  --color-goal-11-shaded: 199, 52, 0;
  --color-goal-12: 191, 139, 46;
  --color-goal-12-shaded: 91, 50, 3;
  --color-goal-13: 63, 126, 68;
  --color-goal-13-shaded: 0, 48, 31;
  --color-goal-14: 10, 151, 217;
  --color-goal-14-shaded: 0, 53, 112;
  --color-goal-15: 86, 192, 43;
  --color-goal-15-shaded: 16, 87, 2;
  --color-goal-16: 0, 104, 157;
  --color-goal-16-shaded: 0, 27, 60;
  --color-goal-17: 25, 72, 106;
  --color-goal-17-shaded: 0, 19, 46;
}

@media (min-width: 600px) {
  :root {
    --page-gutter: 1.5rem;
  }
}

@media (min-width: 1000px) {
  :root {
    --page-gutter: 2rem;
  }
}

@media (min-width: 1200px) {
  :root {
    --page-gutter: 3rem;
  }
}
@import "../../../base/global";

/* GoalGrid */
.GoalGrid-slot--center {
  --current-color: var(---default-color);
  width: 100%;
  position: relative;
  text-align: center;
  background: rgba(var(--color-gray-light), 0.6);
}

.GoalGrid-slot--center .GoalGrid-container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 18%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.GoalGrid-slot--center.GoalGrid-slot--square {
  padding-bottom: 100%;
  position: relative;
}

.GoalGrid-slot--center.GoalGrid-slot--small .GoalGrid-container {
  padding: calc(((100% - var(--gutter, 0) * 2) / 3) * 0.18);
}

.GoalGrid-slot--center.GoalGrid-slot--large .GoalGrid-container {
  padding: calc(((100% - var(--gutter, 0) * 2) / 3) * 0.18);
}

.GoalGrid-slot--center.GoalGrid-slot--fill .GoalGrid-container {
  padding: 1.2rem;
}

@media (min-width: 1200px) {
  .GoalGrid-slot--center.GoalGrid-slot--fill .GoalGrid-container {
    padding: 2rem;
  }
}

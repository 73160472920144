@import "../base";

/* @define View */
.View {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: rgb(var(--default-background-color));
}

/**
 * Header
 */

.View-header {
  position: relative;
  z-index: 5;
}

.View-header--appear {
  opacity: 0;
  will-change: opacity;
  animation: View-header--appearing 600ms 100ms var(--ease) forwards;
}

@keyframes View-header--appearing {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    will-change: unset;
  }
}

.View-header--stuck {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/**
 * 1. Override nested header `--size`
 */

.View-header--secondary {
  --Header-size: 0; /* 1 */
  display: block;
  width: 100%;
  height: 0;
  top: 0;
  margin-bottom: 5rem;
  position: sticky;
}

@media (min-width: 1000px) {
  .View-header--secondary {
    margin-bottom: 8rem;
  }
}

:root[scripting-enabled] .View-header--secondary {
  visibility: hidden;
}

:root[scripting-enabled] .View-header--secondary.is-visible {
  visibility: visible;
  opacity: var(--View-header-visible, 0);
  will-change: opacity;
}

.View-header--secondary.is-visible .View-headerWrapper {
  width: 100%;
  position: fixed;
  top: 0;
}

@supports (position: sticky) {
  .View-header--secondary.is-visible .View-headerWrapper {
    position: static;
  }
}

/**
 * Main content area
 */

.View-main {
  flex-grow: 1;
}

/**
 * Footer
 */

.View-footer::before {
  content: "";
  height: 13vh;
  display: block;
  max-height: 7rem;
}

@media (min-width: 1000px) {
  .View-footer::before {
    max-height: none;
    min-height: 8rem;
  }
}

/**
 * Sidebar
 */

@media (min-width: 1000px) {
  .View-sidebar {
    padding-left: 4rem;
    position: relative;
  }

  .View-sidebar::before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    left: 2rem;
    top: 0;
    background-color: rgb(var(--color-gray-light));
  }

  .View-sidebar > * {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .View-sidebar {
    padding-left: 6rem;
  }

  .View-sidebar::before {
    left: 3rem;
  }
}

/**
 * Sections
 */

.View-space {
  margin: 3rem 0;
}

@media (min-width: 1000px) {
  .View-space {
    margin: 4rem 0;
  }
}

@media (min-width: 1200px) {
  .View-space {
    margin: 5rem 0;
  }
}

/**
 * Small
 */

.View-spaceSmall {
  margin: 2em 0;
}
@media (min-width: 1000px) {
  .View-spaceSmall {
    margin: 2.5em 0;
  }
}

/**
 * Large
 */

.View-spaceLarge {
  margin-bottom: 2rem;
}

.View-spaceLarge::before {
  content: "";
  height: 17vh;
  display: block;
  max-height: 7rem;
}

.View-space + .View-spaceLarge {
  margin-top: -3rem;
}

.View-spaceSmall + .View-spaceLarge {
  margin-top: -2rem;
}

@media (min-width: 1000px) {
  .View-space + .View-spaceLarge {
    margin-top: -4rem;
  }

  .View-spaceSmall + .View-spaceLarge {
    margin-top: -2.5em;
  }

  .View-spaceLarge::before {
    max-height: none;
    min-height: 10rem;
  }
}

@media (min-width: 1200px) {
  .View-space + .View-spaceLarge {
    margin-top: -5rem;
  }
}

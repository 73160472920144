/* @define Background14 */

.Background14 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.Background14-sea {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: scale(1.2);
  will-change: transform, opacity;
}

.Background14-sea.is-visible {
  animation: Background14-sea--appear 1800ms 50ms cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

@keyframes Background14-sea--appear {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/**
 * Jellyfish
 */

.Background14-jellyfish {
  display: none;
  width: 50px;
  height: auto;
  position: absolute;
  top: 100%;
}

.Background14-jellyfish.in-transition {
  transition: transform 15000ms linear;
  transform: translateY(-100vh) translateY(-100%);
}

@media (min-width: 900px) {
  .Background14-jellyfish {
    display: inline;
  }
}

.Background14--small .Background14-jellyfish {
  display: none;
}

/**
 * Fish
 */

.Background14-fish {
  display: block;
  width: 87px;
  height: auto;
  position: absolute;
  right: 100%;
  color: #fff;
}

.Background14-fish.in-transition {
  transition: transform 45000ms linear;
  transform: translateX(100vw) translateX(100%);
}

.Background14-fish--small.in-transition {
  transition-duration: 45000ms;
}

.Background14-fish--medium.in-transition {
  transition-duration: 35000ms;
}

.Background14-fish--large.in-transition {
  transition-duration: 25000ms;
}

.Background14-fish--small {
  width: 57px;
  color: #005A84;
}

.Background14-fish--medium {
  width: 87px;
  color: #3BABE0;
}

.Background14-fish--large {
  width: 123px;
  color: #fff;
}

/**
 * Bubble
 */

.Background14-bubble {
  display: block;
  width: 50px;
  height: auto;
  position: absolute;
  top: 100%;
  color: #fff;
}

.Background14-bubble.in-transition {
  transition: transform 9000ms linear;
  transform: translateY(-100vh) translateY(100%);
}

.Background14-bubble--small.in-transition {
  width: 20px;
  transition-duration: 15000ms;
  color: rgba(255, 255, 255, 0.2);
}

.Background14-bubble--large {
  width: 32px;
  color: #fff;
}

/**
 * Whale
 */

.Background14-whale {
  width: 80%;
  min-width: 140vh;
  height: auto;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translate(35%, -25%);
  color: #3BABE0;
  opacity: 0.75;
}

.Background14--small .Background14-whale {
  display: none;
}

.Background14-whale.has-looped {
  transform: translate(0%, -25%);
}

.Background14-whale.in-transition {
  transform: translate(calc(100vw + 100%), -25%);
  transition: transform 120s linear;
}

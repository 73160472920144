@import "../base";

/** @define Menu */

.Menu {
  padding: 0.875rem 1.25rem;
  background-color: rgb(var(--current-background));
}

@media (min-width: 1000px) {
  .Menu {
    padding: 1.375rem 2rem;
  }
}

.Menu--fill {
  padding: 0;
}

.Menu-title {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

.Menu-description {
  margin: 0;
  font-size: 0.875rem;
}

.Menu-list {
  column-count: 2;
  column-gap: 1.25rem;
  position: relative;
}

.Menu--small .Menu-list {
  column-count: 1;
}

.Menu-list:not(:first-child) {
  margin-top: 1.25rem;
}

@media (min-width: 1000px) {
  .Menu-list {
    column-count: 2;
    column-gap: 2rem;
  }
}

.Menu-list::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgb(var(--current-background));
}

.Menu-item {
  display: flex;
  align-items: center;
  position: relative;
  break-inside: avoid-column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Menu-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-right: 1.25rem;
  position: relative;
}

@media (min-width: 1000px) {
  .Menu-image {
    width: 3rem;
    height: 3rem;
  }
}

.Menu-img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.Menu--bright .Menu-img {
  filter: brightness(100);
}

.Menu-link {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding: 0.75em 0;
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-family: var(--heading-font-family);
  word-spacing: var(--heading-word-spacing);
  line-height: var(--heading-line-height);
}

.Menu-link::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

@media (min-width: 1000px) {
  .Menu-link {
    font-size: 2rem;
  }
}

.Menu-label {
  display: block;
  transition: transform 200ms var(--ease-out);
}

.Menu-link:hover .Menu-label {
  transform: translateX(0.5rem);
}

.Menu-chevron {
  display: block;
  margin-left: auto;
  font-size: 1rem;
  transform: translateX(-0.5rem);
  transition: transform 200ms var(--ease-out);
}

.Menu-link:hover .Menu-chevron {
  transform: translateX(0);
}

/* @define utilities */

.u-slideUp {
  opacity: 0;
  animation: slide-up 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@import "../base";

/**
 * @define Bookmark
 * The Bookmark component is intended to be compatible with just about anything.
 * To prevent generic typography rules from interfering, excessive importance is
 * applied to some selectors, this is intended and neccessary but possible
 * to override with i.e. utilities or selectors using !important.
 */
.Bookmark {
  display: flex;
  position: relative;
  background-color: rgba(var(--color-gray-light), 0.6);
}

.Bookmark .Bookmark-icon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 0.75rem;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.9rem;
  color: rgb(var(--current-color));
}

@media (min-width: 700px) {
  .Bookmark .Bookmark-icon {
    padding: 1rem;
  }
}

.Bookmark .Bookmark-label {
  display: inline-block;
  max-width: 15em;
  padding: 0.4em 0.75em 0.5em;
  font-size: 0.75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgb(var(--current-background));
  background-color: rgb(var(--color-theme));
  border-radius: 1px;
}

.Bookmark .Bookmark-thumbnail {
  flex: 0 0 auto;
  min-width: 26%;
  padding: 0.5rem;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

@media (max-width: 399px) {
  .Bookmark .Bookmark-thumbnail {
    display: none;
  }
}

.Bookmark .Bookmark-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  color: transparent;
}

.Bookmark .Bookmark-body {
  flex: 1 1 auto;
  padding: 0.75rem 1rem;
  overflow: hidden;
}

@media (min-width: 700px) {
  .Bookmark .Bookmark-body {
    padding: 1rem 1.5rem;
  }
}

.Bookmark .Bookmark-title:nth-child(n) {
  margin: 0.2em 0;
  font-size: 1.125rem;
  text-transform: none;
  line-height: 1.3;
  font-family: var(--default-font-family);
}

.Bookmark .Bookmark-description:nth-child(n) {
  margin: 0;
  font-size: 0.875rem;
}

@media (max-width: 699px) {
  .Bookmark .Bookmark-description {
    display: none;
  }
}

.Bookmark .Bookmark-meta {
  color: rgb(var(--color-text-muted));
}

@media (max-width: 699px) {
  .Bookmark .Bookmark-date {
    display: none;
  }
}

.Bookmark .Bookmark-href {
  text-decoration: underline;
}

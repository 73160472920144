/* @define utilities */

/**
 * Proportional widths
 */

.u-size1of1 {
  flex-basis: auto !important;
  width: 100% !important;
}

.u-size1of6 {
  flex-basis: auto !important;
  width: calc(100% * 1 / 6) !important;
}

.u-size1of4 {
  flex-basis: auto !important;
  width: 25% !important;
}

.u-size1of3 {
  flex-basis: auto !important;
  width: calc(100% * 1 / 3) !important;
}

.u-size1of2,
.u-size2of4 {
  flex-basis: auto !important;
  width: 50% !important;
}

.u-size2of3 {
  flex-basis: auto !important;
  width: calc(100% * 2 / 3) !important;
}

.u-size3of4 {
  flex-basis: auto !important;
  width: 75% !important;
}

/**
 * Small
 */

@media (min-width: 400px) {
  .u-sm-size1of1 {
    flex-basis: auto !important;
    width: 100% !important;
  }

  .u-sm-size1of6 {
    flex-basis: auto !important;
    width: calc(100% * 1 / 6) !important;
  }

  .u-sm-size1of4 {
    flex-basis: auto !important;
    width: 25% !important;
  }

  .u-sm-size1of3 {
    flex-basis: auto !important;
    width: calc(100% * 1 / 3) !important;
  }

  .u-sm-size1of2,
  .u-sm-size2of4 {
    flex-basis: auto !important;
    width: 50% !important;
  }

  .u-sm-size2of3 {
    flex-basis: auto !important;
    width: calc(100% * 2 / 3) !important;
  }

  .u-sm-size3of4 {
    flex-basis: auto !important;
    width: 75% !important;
  }
}

/**
 * Medium
 */

@media (min-width: 600px) {
  .u-md-size1of1 {
    flex-basis: auto !important;
    width: 100% !important;
  }

  .u-md-size1of6 {
    flex-basis: auto !important;
    width: calc(100% * 1 / 6) !important;
  }

  .u-md-size1of4 {
    flex-basis: auto !important;
    width: 25% !important;
  }

  .u-md-size1of3 {
    flex-basis: auto !important;
    width: calc(100% * 1 / 3) !important;
  }

  .u-md-size1of2,
  .u-md-size2of4 {
    flex-basis: auto !important;
    width: 50% !important;
  }

  .u-md-size2of3 {
    flex-basis: auto !important;
    width: calc(100% * 2 / 3) !important;
  }

  .u-md-size3of4 {
    flex-basis: auto !important;
    width: 75% !important;
  }
}

/**
 * Large
 */

@media (min-width: 1000px) {
  .u-lg-size1of1 {
    flex-basis: auto !important;
    width: 100% !important;
  }

  .u-lg-size1of6 {
    flex-basis: auto !important;
    width: calc(100% * 1 / 6) !important;
  }

  .u-lg-size1of4 {
    flex-basis: auto !important;
    width: 25% !important;
  }

  .u-lg-size1of3 {
    flex-basis: auto !important;
    width: calc(100% * 1 / 3) !important;
  }

  .u-lg-size1of2,
  .u-lg-size2of4 {
    flex-basis: auto !important;
    width: 50% !important;
  }

  .u-lg-size2of3 {
    flex-basis: auto !important;
    width: calc(100% * 2 / 3) !important;
  }

  .u-lg-size3of4 {
    flex-basis: auto !important;
    width: 75% !important;
  }
}

/**
 * Extra Large
 */

@media (min-width: 1500px) {
  .u-xl-size1of4 {
    flex-basis: auto !important;
    width: 25% !important;
  }

  .u-xl-size1of6 {
    flex-basis: auto !important;
    width: calc(100% * 1 / 6) !important;
  }

  .u-xl-size1of3 {
    flex-basis: auto !important;
    width: calc(100% * 1 / 3) !important;
  }

  .u-xl-size1of2,
  .u-xl-size2of4 {
    flex-basis: auto !important;
    width: 50% !important;
  }

  .u-xl-size2of3 {
    flex-basis: auto !important;
    width: calc(100% * 2 / 3) !important;
  }

  .u-xl-size3of4 {
    flex-basis: auto !important;
    width: 75% !important;
  }
}

/**
 * Intrinsic widths
 */

/**
 * Make an element shrink wrap its content.
 */

.u-sizeFit {
  flex-basis: auto !important;
}

/**
 * Make an element fill the remaining space.
 *
 * 1. Be explicit to work around IE10 bug with shorthand flex
 *    http://git.io/vllC7
 * 2. IE10 ignores previous `flex-basis` value. Setting again here fixes
 *    http://git.io/vllMt
 */

.u-sizeFill {
  flex: 1 1 0% !important; /* 1 */
  flex-basis: 0% !important; /* 2 */
}

/**
 * An alternative method to make an element fill the remaining space.
 * Distributes space based on the initial width and height of the element
 *
 * http://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
 */

.u-sizeFillAlt {
  flex: 1 1 auto !important;
  flex-basis: auto !important;
}

/**
 * Make an element the width of its parent.
 */

.u-sizeFull {
  width: 100% !important;
  max-width: none !important;
  flex: 1 0 100% !important;
}

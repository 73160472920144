/* @define Background7 */

.Background7 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgb(var(--color-goal-7));
}

.Background7-dimmer {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgb(var(--color-goal-7-shaded));
  opacity: 0.6;
  animation: Background7-dimmer--up 800ms 1300ms var(--ease-in) forwards;
}

@keyframes Background7-dimmer--up {
  to {
    opacity: 0;
  }
}

.Background7-energySun {
  width: 70px;
  position: absolute;
  bottom: 15vh;
  left: 12.5%;
  visibility: hidden;
  transform: translateY(100vh);
  animation: Background7-energySun--appear 600ms 200ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

@media (min-width: 900px) {
  .Background7-energySun {
    visibility: visible;
  }
}

@keyframes Background7-energySun--appear {
  to {
    transform: translateY(0vh);
  }
}

.Background7-building {
  height: 230px;
  max-height: 17vh;
  left: 7%;
  width: auto;
  position: absolute;
  bottom: 0;
  visibility: hidden;
  transform: translateY(100%);
  animation: Background7-turbine--appear 600ms 200ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

@media (min-width: 900px) {
  .Background7-building {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .Background7-building {
    left: 17%;
  }
}

.Background7--small .Background7-building {
  display: none;
}

@keyframes Background7-building--appear {
  to {
    transform: translateY(0%);
  }
}

.Background7-turbines {
  width: 100%;
  max-width: 500px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.Background7--small .Background7-turbines {
  max-width: 320px;
}

@media (min-width: 1200px) {
  .Background7:not(.Background7--small) .Background7-turbines {
    right: 7%;
  }
}

.Background7-turbine {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  animation: Background7-turbine--appear 600ms 200ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

@keyframes Background7-turbine--appear {
  to {
    transform: translateY(0%);
  }
}

.Background7-turbine:nth-child(1) {
  height: 132px;
  right: -8%;
}

.Background7-turbine:nth-child(2) {
  height: 240px;
  right: 11%;
}

.Background7-turbine:nth-child(3) {
  height: 90px;
  right: 29%;
  opacity: 0.4;
}

.Background7-turbine:nth-child(4) {
  height: 150px;
  right: 38%;
  opacity: 0.65;
}

.Background7-turbine:nth-child(5) {
  height: 120px;
  right: 57%;
}

.Background7-turbine:nth-child(6) {
  height: 65px;
  right: 65%;
  opacity: 0.4;
}

.Background7--small .Background7-turbine {
  max-height: 170px;
}

.Background7--small .Background7-turbine:nth-child(3) ~ .Background7-turbine {
  display: none;
}

.Background7-blades {
  transform-origin: 50% 50%;
  animation: Background7-blades--spin 3000ms 1750ms linear infinite;
}

.Background7-turbine:nth-child(1) .Background7-blades { animation-delay: 1900ms; }
.Background7-turbine:nth-child(2) .Background7-blades { animation-delay: 1750ms; }
.Background7-turbine:nth-child(3) .Background7-blades { animation-delay: 2050ms; }
.Background7-turbine:nth-child(4) .Background7-blades { animation-delay: 1950ms; }
.Background7-turbine:nth-child(5) .Background7-blades { animation-delay: 1900ms; }
.Background7-turbine:nth-child(6) .Background7-blades { animation-delay: 2100ms; }

@keyframes Background7-blades--spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Background7-lightbulb {
  height: 50%;
  position: absolute;
  top: 0;
  will-change: transform;
  transform: translateY(-100%);
  animation: Background7-lightBulb--appear 450ms 200ms forwards;
}

@keyframes Background7-lightBulb--appear {
  to {
    transform: translateY(0%);
  }
}

.Background7-lightbulb:nth-child(1) {
  display: none;
}

.Background7-lightbulb:nth-child(2) {
  right: 20%;
  top: -30vh;
}

.Background7-lightbulb:nth-child(3) {
  right: 5%;
  top: -20vh;
}

@media (min-width: 900px) {
  .Background7:not(.Background7--small) .Background7-lightbulb:nth-child(1) {
    display: block;
    left: 8%;
    top: -20vh;
  }

  .Background7-lightbulb:nth-child(2) {
    right: 18%;
    top: -15vh;
  }

  .Background7-lightbulb:nth-child(3) {
    right: 8%;
  }
}

.Background7--small .Background7-lightbulb:nth-child(3) {
  display: none;
}

.Background7--small .Background7-lightbulb:nth-child(2) {
  right: 16%;
  top: -17.6%;
  height: 63%;
}

.Background7-lightRay {
  stroke-dasharray: 10;
  stroke-dashoffset: 10;
  animation: Background7-lightRay--appear 280ms forwards;
}

@keyframes Background7-lightRay--appear {
  to {
    stroke-dashoffset: 0;
  }
}

.Background7-bulbRays .Background7-lightRay:nth-child(1) {
  animation-delay: 900ms;
}

.Background7-bulbRays .Background7-lightRay:nth-child(2) {
  animation-delay: 1050ms;
}

.Background7-bulbRays .Background7-lightRay:nth-child(3) {
  animation-delay: 1200ms;
}

.Background7-bulbRays .Background7-lightRay:nth-child(4) {
  animation-delay: 1350ms;
}

.Background7-bulbRays .Background7-lightRay:nth-child(5) {
  animation-delay: 1500ms;
}

.Background7-bulbRays .Background7-lightRay:nth-child(6) {
  animation-delay: 1650ms;
}

.Background7-bulbRays .Background7-lightRay:nth-child(7) {
  animation-delay: 1800ms;
}

.Background7-bulbRays .Background7-lightRay:nth-child(8) {
  animation-delay: 1950ms;
}

.Background7-bulbRays .Background7-lightRay:nth-child(9) {
  animation-delay: 2100ms;
}

.Background7-energySun .Background7-lightRay {
  stroke-dashoffset: 6;
  animation-duration: 1200ms;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

.Background7--small .Background7-energySun {
  display: none;
}

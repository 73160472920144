/* @define utilities */

@import "../global";

.u-textBold {
  font-weight: 600 !important;
}

.u-textSemiBold {
  font-weight: 500 !important;
}

.u-textRegular {
  font-weight: normal !important;
}

.u-textItalic {
  font-style: italic !important;
}

.u-textCenter {
  text-align: center !important;
}

.u-textRight {
  text-align: right !important;
}

.u-textLeft {
  text-align: left !important;
}

.u-textInherit {
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  text-transform: inherit !important;
  text-align: inherit !important;
  font-style: inherit !important;
}

.u-textNowrap {
  white-space: nowrap !important;
}

.u-textWordBreak {
  word-break: break-word !important;
}

.u-textHyphens {
  hyphens: auto !important;
}

.u-textDefault {
  font-family: var(--default-font-family) !important;
  line-height: var(--default-line-height) !important;
  letter-spacing: var(--default-font-size) !important;
  text-transform: normal;
  word-spacing: normal;
}

.u-textHeading {
  font-family: var(--heading-font-family) !important;
  line-height: var(--heading-line-height) !important;
  word-spacing: var(--heading-word-spacing) !important;
  text-transform: uppercase;
}

.u-rtl {
  direction: rtl !important;
}

.u-nowrap {
  white-space: nowrap !important;
}

/**
 * Prevent text from wrapping onto multiple lines and truncate with an ellipsis.
 * 1. Ensure that the node has a maximum width after which truncation can occur.
 */

.u-textTruncate {
  display: block !important;
  max-width: 100% !important; /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

/**
 * Source: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
 */

.u-textBreakLongWords {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

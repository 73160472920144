@import "../base";
@import "../goal";
@import "../text";

/* @define GoalGrid */
.GoalGrid {
  --supports-layout: 0;
  margin: calc((var(--gutter, 0.625rem) / 2) * -1);
  user-select: none;
}

/**
 * Clearfix
 */

.GoalGrid::before,
.GoalGrid::after {
  content: " ";
  display: table;
}

.GoalGrid::after {
  clear: both;
}

@media print {
  .GoalGrid {
    display: none !important;
  }
}

.GoalGrid-item {
  display: block;
  float: left;
  width: 50%;
  padding: calc(var(--gutter, 0.625rem) / 2) calc(var(--gutter, 0.625rem) / 2);
  position: relative;
  transform: scale(1);
  transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.GoalGrid-item:focus-visible {
  outline-color: #000 !important;
}

.GoalGrid-item--portrait,
.GoalGrid-item--landscape {
  display: none;
}

.GoalGrid-item--large,
.GoalGrid-item--small {
  display: none;
}

.GoalGrid-item--small::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  padding-top: calc((100% - var(--gutter, 0.625rem) * 2) / 3);
}

@media (min-width: 500px) {
  .GoalGrid-item {
    width: calc(100% / 3);
  }
}

@media (min-width: 700px) {
  .GoalGrid-item {
    width: 25%;
  }

  .GoalGrid--layout .GoalGrid-item--slot.GoalGrid-item--square {
    display: none;
  }

  .GoalGrid--layout .GoalGrid-item--small {
    display: flex;
    width: 75%;
  }
}

@media (min-width: 1200px) {
  .GoalGrid-item {
    width: calc(100% / 6);
  }

  /**
   * 1. Fix for incorrect pixel rounding (Edge)
   */

  .GoalGrid-item--6,
  .GoalGrid-item--12,
  .GoalGrid-item--logo {
    margin-right: -1px; /* 1 */
  }
}

@media (min-width: 1200px) {
  .GoalGrid--layout .GoalGrid-item--small {
    display: none;
  }

  .GoalGrid--layout .GoalGrid-item--slot.GoalGrid-item--square {
    display: block;
  }
}

@supports (display: grid) {
  @media (min-width: 900px) {
    .GoalGrid {
      --supports-layout: 1;
    }

    .GoalGrid::before,
    .GoalGrid::after {
      content: none;
    }

    :root[scripting-enabled] .GoalGrid--layout .GoalGrid-animation {
      display: block;
      overflow: hidden;
    }

    .GoalGrid--layout {
      margin: 0;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(6, 1fr);
      grid-gap: var(--gutter, 0.625rem);
    }

    .GoalGrid--layout .GoalGrid-item {
      float: none;
      width: auto;
      padding: 0;
    }

    .GoalGrid--layout .GoalGrid-item--large {
      display: flex;
    }

    .GoalGrid-item--small::before {
      content: none;
    }

    .GoalGrid-item--portrait,
    .GoalGrid-item--landscape {
      display: block;
    }

    /**
     * Layouts
     */

    /* 1. */

    .GoalGrid--layout1 .GoalGrid-item--1.GoalGrid-item--square,
    .GoalGrid--layout1 .GoalGrid-item--6.GoalGrid-item--square { display: none; }
    .GoalGrid--layout1 .GoalGrid-item--1 { grid-area: 1 / 1 / 3 / 4; }
    .GoalGrid--layout1 .GoalGrid-item--6 { grid-area: 2 / 5 / 5 / 7; }
    .GoalGrid--layout1 .GoalGrid-item--small { grid-area: 6 / 4 / 7 / 7; }
    .GoalGrid--layout1 .GoalGrid-item--large { grid-area: 4 / 1 / 6 / 4; }

    /* 2. */

    .GoalGrid--layout2 .GoalGrid-item--3.GoalGrid-item--square,
    .GoalGrid--layout2 .GoalGrid-item--8.GoalGrid-item--square { display: none; }
    .GoalGrid--layout2 .GoalGrid-item--3 { grid-area: 1 / 3 / 3 / 6; }
    .GoalGrid--layout2 .GoalGrid-item--8 { grid-area: 3 / 1 / 6 / 3; }
    .GoalGrid--layout2 .GoalGrid-item--small { grid-area: 6 / 4 / 7 / 7; }
    .GoalGrid--layout2 .GoalGrid-item--large { grid-area: 3 / 4 / 5 / 7; }

    /* 3. */

    .GoalGrid--layout3 .GoalGrid-item--17.GoalGrid-item--square,
    .GoalGrid--layout3 .GoalGrid-item--7.GoalGrid-item--square { display: none; }
    .GoalGrid--layout3 .GoalGrid-item--17 { grid-area: 5 / 4 / 7 / 7; }
    .GoalGrid--layout3 .GoalGrid-item--7 { grid-area: 2 / 1 / 5 / 3; }
    .GoalGrid--layout3 .GoalGrid-item--small { grid-area: 6 / 1 / 6 / 4; }
    .GoalGrid--layout3 .GoalGrid-item--large { grid-area: 2 / 4 / 4 / 7; }

    /* 4. */

    .GoalGrid--layout4 .GoalGrid-item--13.GoalGrid-item--square,
    .GoalGrid--layout4 .GoalGrid-item--2.GoalGrid-item--square { display: none; }
    .GoalGrid--layout4 .GoalGrid-item--13 { grid-area: 7 / 3 / 5 / 6; }
    .GoalGrid--layout4 .GoalGrid-item--2 { grid-area: 1 / 2 / 4 / 4; }
    .GoalGrid--layout4 .GoalGrid-item--small { grid-area: 4 / 4 / 5 / 7; }
    .GoalGrid--layout4 .GoalGrid-item--large { grid-area: 1 / 4 / 3 / 7; }

    /* 5. */

    .GoalGrid--layout5 .GoalGrid-item--9.GoalGrid-item--square,
    .GoalGrid--layout5 .GoalGrid-item--16.GoalGrid-item--square { display: none; }
    .GoalGrid--layout5 .GoalGrid-item--9 { grid-area: 2 / 3 / 4 / 6; }
    .GoalGrid--layout5 .GoalGrid-item--16 { grid-area: 4 / 5 / 7 / 7; }
    .GoalGrid--layout5 .GoalGrid-item--small { grid-area: 6 / 1 / 6 / 4; }
    .GoalGrid--layout5 .GoalGrid-item--large { grid-area: 4 / 1 / 6 / 4; }

    /* 6. */

    .GoalGrid--layout6 .GoalGrid-item--10.GoalGrid-item--square,
    .GoalGrid--layout6 .GoalGrid-item--11.GoalGrid-item--square { display: none; }
    .GoalGrid--layout6 .GoalGrid-item--10 { grid-area: 2 / 4 / 4 / 7; }
    .GoalGrid--layout6 .GoalGrid-item--11 { grid-area: 3 / 1 / 6 / 3; }
    .GoalGrid--layout6 .GoalGrid-item--small { grid-area: 6 / 1 / 7 / 4; }
    .GoalGrid--layout6 .GoalGrid-item--large { grid-area: 4 / 4 / 6 / 7; }

    /* 7. */

    .GoalGrid--layout7 .GoalGrid-item--1.GoalGrid-item--square,
    .GoalGrid--layout7 .GoalGrid-item--12.GoalGrid-item--square { display: none; }
    .GoalGrid--layout7 .GoalGrid-item--1 { grid-area: 1 / 1 / 3 / 4; }
    .GoalGrid--layout7 .GoalGrid-item--12 { grid-area: 3 / 5 / 6 / 7; }
    .GoalGrid--layout7 .GoalGrid-item--small { grid-area: 6 / 1 / 6 / 4; }
    .GoalGrid--layout7 .GoalGrid-item--large { grid-area: 4 / 1 / 6 / 4; }

    /* 8. */

    .GoalGrid--layout8 .GoalGrid-item--15.GoalGrid-item--square,
    .GoalGrid--layout8 .GoalGrid-item--5.GoalGrid-item--square { display: none; }
    .GoalGrid--layout8 .GoalGrid-item--15 { grid-area: 5 / 3 / 7 / 6; }
    .GoalGrid--layout8 .GoalGrid-item--5 { grid-area: 1 / 5 / 4 / 7; }
    .GoalGrid--layout8 .GoalGrid-item--small { grid-area: 2 / 1 / 3 / 4; }
    .GoalGrid--layout8 .GoalGrid-item--large { grid-area: 3 / 1 / 5 / 4; }

    /* 9. */

    .GoalGrid--layout9 .GoalGrid-item--14.GoalGrid-item--square,
    .GoalGrid--layout9 .GoalGrid-item--4.GoalGrid-item--square { display: none; }
    .GoalGrid--layout9 .GoalGrid-item--14 { grid-area: 5 / 4 / 7 / 7; }
    .GoalGrid--layout9 .GoalGrid-item--4 { grid-area: 1 / 4 / 4 / 6; }
    .GoalGrid--layout9 .GoalGrid-item--small { grid-area: 2 / 1 / 3 / 4; }
    .GoalGrid--layout9 .GoalGrid-item--large { grid-area: 5 / 1 / 7 / 4; }
  }

  @media (min-width: 1200px) {
    .GoalGrid--layout .GoalGrid-item--small {
      display: flex;
    }

    .GoalGrid--layout .GoalGrid-item--slot.GoalGrid-item--square {
      display: none;
    }
  }
}

.GoalGrid-bg::before {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 180ms var(--ease-out);
}

/**
 * 1. Hardcoded in JavaScript, see link.js
 */

.GoalGrid-item.is-pressed {
  transform: scale(0.97); /* 1 */
}

.GoalGrid-item.is-pressed::before {
  opacity: 0;
  transition: opacity 100ms var(--ease-out);
}

/**
 * Content
 */

.GoalGrid-content {
  display: flex;
  align-items: flex-start;
}

.GoalGrid-item--portrait .GoalGrid-content {
  flex-direction: column;
}

.GoalGrid-item--landscape .GoalGrid-content {
  align-items: flex-end;
  justify-content: space-between;
}

.GoalGrid-description {
  padding-right: 1.5rem;
}

.GoalGrid-item--7 .GoalGrid-description {
  color: rgb(0, 0, 0);
}

.GoalGrid-item--portrait .GoalGrid-description {
  max-width: 19em;
}

.GoalGrid-item--landscape .GoalGrid-description {
  max-width: 24em;
}

@media (min-width: 1200px) {
  .GoalGrid-description {
    font-size: 1rem;
  }
}

/**
 * 1. Tap into contextual goal color
 */

.GoalGrid-button {
  display: none;
  padding: 0.65em 1em 0.55em;
  margin-top: 1em;
  color: transparent;
  font-size: 1.5rem;
  font-family: var(--heading-font-family);
  line-height: var(--heading-line-height);
  word-spacing: var(--heading-word-spacing);
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  background: #fff;
  text-shadow: 0 0 var(--Goal-background); /* 1 */
}

@media (min-width: 1000px) {
  .GoalGrid-button {
    display: inline-block;
  }
}

.GoalGrid-item--7 .GoalGrid-button {
  background: #000;
  text-shadow: none;
  color: #fff;
}

.GoalGrid-item--1:hover .GoalGrid-button { color: rgba(var(--color-goal-1-shaded), 0.13); }
.GoalGrid-item--2:hover .GoalGrid-button { color: rgba(var(--color-goal-2-shaded), 0.13); }
.GoalGrid-item--3:hover .GoalGrid-button { color: rgba(var(--color-goal-3-shaded), 0.13); }
.GoalGrid-item--4:hover .GoalGrid-button { color: rgba(var(--color-goal-4-shaded), 0.13); }
.GoalGrid-item--5:hover .GoalGrid-button { color: rgba(var(--color-goal-5-shaded), 0.13); }
.GoalGrid-item--6:hover .GoalGrid-button { color: rgba(var(--color-goal-6-shaded), 0.13); }
.GoalGrid-item--7:hover .GoalGrid-button { color: #fff; }
.GoalGrid-item--8:hover .GoalGrid-button { color: rgba(var(--color-goal-8-shaded), 0.13); }
.GoalGrid-item--9:hover .GoalGrid-button { color: rgba(var(--color-goal-9-shaded), 0.13); }
.GoalGrid-item--10:hover .GoalGrid-button { color: rgba(var(--color-goal-10-shaded), 0.13); }
.GoalGrid-item--11:hover .GoalGrid-button { color: rgba(var(--color-goal-11-shaded), 0.13); }
.GoalGrid-item--12:hover .GoalGrid-button { color: rgba(var(--color-goal-12-shaded), 0.13); }
.GoalGrid-item--13:hover .GoalGrid-button { color: rgba(var(--color-goal-13-shaded), 0.13); }
.GoalGrid-item--14:hover .GoalGrid-button { color: rgba(var(--color-goal-14-shaded), 0.13); }
.GoalGrid-item--15:hover .GoalGrid-button { color: rgba(var(--color-goal-15-shaded), 0.13); }
.GoalGrid-item--16:hover .GoalGrid-button { color: rgba(var(--color-goal-16-shaded), 0.13); }
.GoalGrid-item--17:hover .GoalGrid-button { color: rgba(var(--color-goal-17-shaded), 0.13); }

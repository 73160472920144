@import "../base";

/* @define Button */
.Button {
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 3.25rem;
  padding: 0 1.2rem;
  font-size: 1.5rem;
  color: rgb(var(--default-color));
  background-color: rgba(var(--color-gray), 0.12);
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  user-select: none;
  font-family: var(--heading-font-family);
  word-spacing: var(--heading-word-spacing);
  transition: 140ms var(--ease-out);
  transition-property: background-color, color;
}

.Button--small {
  padding: 0 0.9rem;
  min-width: 0;
  line-height: 2.7rem;
}

.Button--large {
  padding: 0.3em 2rem 0.25em;
  min-width: 0;
  font-size: 2.6em;
}

.Button:not([disabled]):hover {
  background-color: rgba(var(--color-gray), 0.22);
}

.Button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

/**
 * Primary button
 */

.Button--primary {
  background: rgb(var(--color-link));
  color: rgb(var(--current-background));
}

.Button--primary:not([disabled]):hover {
  background: rgb(var(--color-link-shaded));
  color: #fff;
}

/**
 * Secondary button
 */
.Button--secondary {
  background:#fff;
  color: #000;
}

.Button--secondary:not([disabled]) {
  cursor: pointer;
}

.Button--secondary:not([disabled]):hover {
  background: #fff;
  color: rgba(0,0,0, .5);
}

/**
 * Button covering offset parent
 */

.Button--cover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

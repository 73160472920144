@import "../base/";

/* @define Border */
.Border {
  padding: 0 1rem;
  margin-bottom: 1.5rem;
  background-color: rgb(var(--current-color));
  color: rgb(var(--current-background));
}

.Border.is-loading {
  background-color: rgba(var(--color-gray-light), 0.6) !important;
  color: transparent;
}

.Border-text {
  color: inherit;
  font-size: 1.5rem;
  line-height: 1.8em;
  font-weight: 600;
  text-transform: uppercase;
  font-family: var(--heading-font-family);
  word-spacing: var(--heading-word-spacing);
  white-space: nowrap;
}

@media (min-width: 1000px) {
  .Border {
    padding: 0 1em;
  }

  .Border-text {
    line-height: 2.7;
  }
}

@media print {
  .Border {
    color: rgb(var(--current-color));
    background-color: rgb(var(--current-background));
    padding: 0 !important;
    margin-top: 3rem !important;
  }

  .Border-text {
    font-size: 2.5rem;
  }
}
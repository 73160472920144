@import "../base";

/* @define Popular */
.Popular {
  position: relative;
}

.Popular--slim {
  margin: -1.5rem 0;
}

.Popular-heading {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 1rem;
  color: rgb(var(--color-gray));
}

@media (min-width: 1200px) {
  .Popular-heading {
    font-size: 1.25rem;
  }
}

.Popular-item {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 1rem 1rem;
  margin: 0 -1rem;
}

.Popular--slim .Popular-item {
  padding: 0;
  margin: 1.5rem 0;
}

.Popular-item::before {
  content: "";
  width: 100%;
  height: calc(100% + 1px);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.04);
  transition: opacity 150ms var(--ease-out);
  will-change: opacity;
}

.Popular-item:hover::before {
  opacity: 1;
}

.Popular:not(.Popular--slim) .Popular-item::after {
  content: "";
  width: calc(100% - 2rem);
  height: 1px;
  position: absolute;
  left: 1rem;
  top: 0;
  background-color: rgb(var(--color-gray-light));
}

.Popular-image {
  flex: 0 0 90px;
  height: auto;
  margin-right: 1.5rem;
}

@media (max-width: 400px), (min-width: 600px) and (max-width: 800px), (min-width: 1000px) and (max-width: 1200px) {
  .Popular-image {
    display: none;
  }
}

.Popular-body {
  flex: 1 1 auto;
  line-height: 1.3;
  padding-right: 1rem;
}

.Popular-date {
  display: block;
  margin-bottom: 0.2em;
  font-size: 0.8rem;
  color: rgba(var(--color-text-muted));
}

.Popular-title {
  font-weight: bold;
  line-height: 1.5;
  color: rgb(var(--current-color));
}

.Popular--slim .Popular-title {
  font-size: 0.9375rem;
}

.Popular-link {
  align-self: center;
  font-size: 0.8rem;
  color: rgb(var(--color-gray));
}

/* We can't really import base here as this file is embedded in the javascript
 * without running through postcss
 * @import "../base";
 */

:root {
  --Chart-scale-factor: 1;
}

/** @define Chart */
.Chart,
.Chart-graph {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: rgba(var(--color-current));
  font-family: var(--default-font-family);
  font-size: 1rem;
  line-height: var(--default-line-height);
}

.Chart-graph {
  width: 100%;
  height: auto;
  font-size: calc(var(--default-font-size) * var(--Chart-scale-factor));
}

.Chart-legend {
  cursor: default;
  font-size: 0.7rem;
  margin-top: 0.2rem;
}

.Chart-heading {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.Chart-title {
  flex: 1 1 50%;
  font-weight: 600;
}

.Chart-source {
  font-weight: normal;
}

.Chart-legend {
  flex: 1 1 auto;
  padding-left: 2rem;
  text-align: right;
}

.Chart-marker {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-left: 1ch;
  position: relative;
  top: 0.15em;
  background-color: currentColor;
}

.Chart-label--md,
.Chart-label--lg,
.Chart-label--xl,
.Chart-label--xxl {
  font-family: var(--heading-font-family);
  word-spacing: var(--heading-word-spacing);
  line-height: var(--heading-line-height);
  text-transform: uppercase;
}

.Chart-label--sm {
  fill: #949494;
  font-size: calc(0.6875rem * var(--Chart-scale-factor));
}

.Chart-label--md {
  font-size: calc(1.5rem * var(--Chart-scale-factor));
}

.Chart-label--lg {
  font-size: calc(2.5rem * var(--Chart-scale-factor));
}

.Chart-label--xl {
  font-size: 9rem;
}

.Chart-label--xxl {
  font-size: 15rem;
}

.Chart-label--dark {
  fill: currentColor;
}

.Chart-label--light {
  fill: #fff;
}

.Chart-link {
  text-decoration: underline;
}

.Chart-pie {
  transform-origin: bottom;
  transform: translateY(-10%) scale(1.3);
}

.Chart--pie.Chart--standalone .Chart-legend {
  transform: translateY(-4.5em);
}

.Chart--md .Chart-pie {
  transform: scale(1.1);
}

:root[scripting-enabled] .Chart:not(.has-fallback) .Chart-fallback {
  display: none;
}

@media (min-width: 600px) and (max-width: 999px) {
  .Chart--shrink {
    --Chart-scale-factor: 1.4;
  }

  .Chart--shrink .Chart-legend {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    margin-top: 1rem;
    text-align: left;
  }

  .Chart--shrink .Chart-marque {
    flex: 1 1 50%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: baseline;
  }

  .Chart--shrink .Chart-marque:nth-child(2n+1) {
    padding-right: 1ch;
  }

  .Chart--shrink .Chart-marker {
    margin-left: 0;
    margin-right: 1ch;
  }

  .Chart--shrink .Chart-label--md {
    font-size: calc(1rem * var(--Chart-scale-factor));
  }

  .Chart--shrink .Chart-label--lg {
    font-size: calc(2rem * var(--Chart-scale-factor));
  }
}

@media (max-width: 479px) {
  :root {
    --Chart-scale-factor: 1.4;
  }

  .Chart-legend {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    margin-top: 1rem;
    text-align: left;
  }

  .Chart-marque {
    flex: 1 1 50%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: baseline;
  }

  .Chart-marque:nth-child(2n+1) {
    padding-right: 1ch;
  }

  .Chart-marker {
    margin-left: 0;
    margin-right: 1ch;
  }

  .Chart-label--md {
    font-size: calc(1rem * var(--Chart-scale-factor));
  }

  .Chart-label--lg {
    font-size: calc(2rem * var(--Chart-scale-factor));
  }

  .Chart--bar.Chart--standalone .Chart-legend {
    display: none;
  }

  .Chart--standalone .Chart-pie {
    transform: scale(1.2);
  }

  .Chart--pie.Chart--standalone .Chart-legend {
    transform: translateY(-2em);
  }
}

@media (max-width: 319px) {
  :root {
    --Chart-scale-factor: 1.8;
  }

  .Chart--standalone .Chart-pie {
    transform: scale(1);
  }

  .Chart--pie.Chart--standalone .Chart-legend {
    transform: translateY(-1em);
  }
}

.Chart-bar {
  transform: scaleY(0);
  transform-origin: bottom;
}

:root:not([scripting-enabled]) .Chart-bar,
:root[scripting-enabled] .Chart.in-view .Chart-bar {
  animation: Chart-bar--grow 800ms forwards var(--ease-out);
}

@keyframes Chart-bar--grow {
  to {
    transform: scaleY(1);
  }
}

.Chart-label {
  opacity: 0;
  transform-origin: center;
}

:root:not([scripting-enabled]) .Chart-label,
:root[scripting-enabled] .Chart.in-view .Chart-label {
  animation: Chart-label--slidein 400ms forwards var(--ease-out);
}

:root:not([scripting-enabled]) .Chart-label--xl,
:root[scripting-enabled] .Chart.in-view .Chart-label--xl,
:root:not([scripting-enabled]) .Chart-label--xxl,
:root[scripting-enabled] .Chart.in-view .Chart-label--xxl {
  animation-duration: 600ms;
}

@keyframes Chart-label--slidein {
  from {
    opacity: 0;
    transform: translateY(40px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.Chart-arrow {
  opacity: 0;
}

:root:not([scripting-enabled]) .Chart-arrow,
:root[scripting-enabled] .Chart.in-view .Chart-arrow {
  animation: Chart-arrow--slidein 400ms forwards var(--ease-out);
}

@keyframes Chart-arrow--slidein {
  from {
    opacity: 0;
    transform: translate(-20px, 20px);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

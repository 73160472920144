/* @define Logo */

@import "../base";

.Logo {
  display: flex;
  align-items: center;
  user-select: none;
}

.Logo-symbol {
  display: block;
  height: 2.9em;
  width: 2.9em;
  margin-right: 0.46em;
}

.Logo-text {
  display: flex;
  flex-direction: column;
}

.Logo-title {
  font-family: var(--heading-font-family);
  word-spacing: 0.015em;
  text-transform: uppercase;
  display: block;
  line-height: 1em;
  font-size: 1.42em;
  letter-spacing: 0.005em;
  white-space: nowrap;
  font-display: block;
}

.Logo-sub {
  font-family: helvetica, Arial, Helvetica, sans-serif;
  font-size: 0.58em;
  letter-spacing: -0.01em;
  line-height: 1em;
  white-space: nowrap;
  margin-top: -0.1em;
  margin-left: 0.1em;
}

/**
 * Vertical
 */

.Logo--vertical {
  flex-direction: column;
  text-align: center;
}

.Logo--vertical .Logo-symbol {
  display: inline-block;
  margin-right: 0;
  margin-bottom: 0.46em;
  width: 4.9em;
  height: 4.8em;
}

.Logo--vertical .Logo-sub {
  margin-left: 0;
}
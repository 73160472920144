@import "../base";

/** @define Symbol */
.Symbol {
  display: inline-block;
  vertical-align: text-top;
  line-height: 1;
}

.Symbol-image {
  width: 1.15em;
  height: 1.15em;
  vertical-align: text-bottom;
}

.Symbol--cover::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.Symbol--circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.5em;
  height: 2.5em;
  color: rgb(var(--current-color));
  color: rgb(var(--high-contrast-light, var(--current-color)));
  background-color: rgb(var(--current-background));
  background-color: rgb(var(--high-contrast-dark, var(--current-background)));
  border-radius: 100%;
  transition: background-color 140ms var(--ease-out);
}

.Symbol--circle .Symbol-image {
  max-width: 100%;
  max-height: 100%;
}

/**
 * Variants
 */

.Symbol--calendar {
  margin-top: -0.12em;
}

.Symbol--external {
  margin-top: -0.05em;
}

.Symbol--external .Symbol-arrow {
  will-change: transform;
  transition: transform 100ms var(--ease-out);
}

.Symbol--external:hover .Symbol-arrow {
  transform: translate(2px, -2px);
}

.Symbol--share .Symbol-image {
  transform: translateY(-0.15em);
}

.Symbol--share .Symbol-arrow {
  will-change: transform;
  transition: transform 100ms var(--ease-out);
}

.Symbol--share:hover .Symbol-arrow {
  transform: translateY(-0.1em);
}

.Symbol--mail .Symbol-image {
  transform-origin: 100% 100%;
  transition: transform 100ms var(--ease-out);
}

.Symbol--mail:hover .Symbol-image {
  transform: rotate(10deg);
}

.Symbol--backward .Symbol-image,
.Symbol--forward .Symbol-image {
  width: 1em;
  transition: transform 100ms var(--ease-out);
}

.Symbol--backward .Symbol-image {
  transform: translateX(0.2em);
}

.Symbol--forward .Symbol-image {
  transform: translateX(-0.2em);
}

.Symbol--backward:hover .Symbol-image,
.Symbol--forward:hover .Symbol-image {
  transform: translateX(0em);
}

.Symbol--download .Symbol-arrow {
  transform: translateY(-0em);
  transition: transform 100ms var(--ease-out);
}

.Symbol--download:hover .Symbol-arrow {
  transform: translateY(0.1em);
}

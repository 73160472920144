@import "../base";

:root {
  --Overlay-preview-thumbnail-size: 4rem;
}

/* @define Overlay */
.Overlay {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 100%;
  padding: 2rem;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 30;
  user-select: none;
  overflow-y: auto;
  background-color: rgb(var(--default-background-color));
}

.Overlay--hidden {
  display: none;
}

@media (min-width: 1000px) {
  .Overlay {
    padding: 3rem;
  }
}

.Overlay-container {
  flex: 1 1 0%;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding-top: 3rem;
  position: relative;
}

@media (min-height: 480px) {
  .Overlay-container {
    padding-top: 0;
  }
}

@media (min-width: 600px) {
  .Overlay-container {
    flex-direction: column;
  }
}

.Overlay-close {
  box-sizing: content-box;
  width: 1.5rem;
  height: 1.5rem;
  padding: 1rem;
  position: absolute;
  top: -1rem;
  right: -1rem;
  border-radius: 2px;
  transition: background-color 180ms var(--ease-out);
}

.Overlay-close::before,
.Overlay-close::after {
  content: "";
  width: 1.5rem;
  height: 2px;
  position: absolute;
  right: 1rem;
  top: 50%;
  background: currentColor;
}

.Overlay-close::before {
  transform: rotate(45deg);
}

.Overlay-close::after {
  transform: rotate(-45deg);
}

.Overlay-close:hover {
  background: rgb(var(--color-gray-light));
}

.Overlay-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overlay-grid .Overlay-heading {
  position: static;
  margin-bottom: 1rem;
  text-align: left;
}

.overlay-grid .column {
  text-align: left;
  padding: 2.5rem 0 0;
}

@media (min-width: 1000px) and (min-height: 480px) {
  .Overlay-body {
    position: relative;
    top: -1.5rem;
  }

  .overlay-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding-top: 5vh;
  }

  /* .overlay-grid .Overlay-heading {
    text-align: center;
  } */

  .overlay-grid .column {
    flex: 1;
    /* text-align: center; */
    padding: 2.5rem;
  }

  .overlay-grid .body {
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
  }
}

.Overlay-heading {
  position: absolute;
  left: 30px;
  top: 32px;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-family: var(--heading-font-family);
  line-height: var(--heading-line-height);
  word-spacing: var(--heading-word-spacing);
  font-weight: bold;
}

@media (min-width: 600px) {
  .Overlay-heading {
    position: static;
    margin-bottom: 1.5rem;
    font-size: 3rem;
    text-align: center;
  }
}

.Overlay-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0 -1.25rem;
}

@media (min-width: 600px) {
  .Overlay-options {
    flex: 0 0 auto;
  }
}

.Overlay-option {
  width: calc(100% / 3);
  padding: 1.25rem 0.5rem;
  text-align: center;
  opacity: 0;
  transform: translateY(100%);
  animation: Overlay-option--appear 600ms cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

.Overlay-option:nth-child(1) {
  animation-delay: 150ms;
}

.Overlay-option:nth-child(2) {
  animation-delay: 175ms;
}

.Overlay-option:nth-child(3) {
  animation-delay: 200ms;
}

.Overlay-option:nth-child(4) {
  animation-delay: 225ms;
}

.Overlay-option:nth-child(5) {
  animation-delay: 250ms;
}

.Overlay-option:nth-child(6) {
  animation-delay: 275ms;
}

@keyframes Overlay-option--appear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 600px) {
  .Overlay-option {
    width: auto;
    padding: 1.25rem;
  }
}

.Overlay-link {
  display: block;
  font-size: 0.875rem;
}

@media (min-width: 600px) {
  .Overlay-link {
    font-size: 1em;
  }
}

.Overlay-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  padding: 1rem;
  margin: 0 auto 1rem;
  position: relative;
  font-size: 1.5rem;
  border-radius: 50%;
  background: rgb(var(--color-gray-light));
  color: rgb(var(--default-background-color));
  transition: transform 150ms var(--ease-out);
}

.Overlay-icon--facebook {
  background: rgb(var(--color-facebook));
}

.Overlay-icon--twitter {
  background: rgb(var(--color-twitter));
}

.Overlay-icon--download {
  background: rgb(var(--color-link));
}

.Overlay-icon--mail {
  background: rgb(var(--color-gray));
}

.Overlay-link:hover .Overlay-icon {
  transform: scale(1.12);
}

.Overlay-link:active .Overlay-icon {
  transform: scale(0.95);
  transition: transform 100ms var(--ease-out);
}

@media (min-width: 600px) and (min-height: 480px) {
  .Overlay-icon {
    width: 4rem;
    height: 4rem;
  }
}

.column {
  min-height: var(--Overlay-preview-thumbnail-size);
  width: 100%;
  transform: translateY(calc(10% + 1rem));
  opacity: 0;
  animation: Overlay-column--appear 600ms 400ms cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

.Overlay-preview {
  display: none;
  min-height: var(--Overlay-preview-thumbnail-size);
  width: 100%;
  transform: translateY(calc(100% + 1rem));
  opacity: 0;
  animation: Overlay-preview--appear 600ms 400ms cubic-bezier(0.19, 1, 0.22, 1) forwards;
  display: flex;
  margin-top: 2.5rem;
}
@media (min-width: 600px){
  .Overlay-preview {
    margin-top: 0;
  }
}

.Overlay-preview .Overlay-badge{
  display: flex;
  flex-direction: column;
}
.Overlay-preview figure.Overlay-badge{

}

.Overlay-preview figure.Overlay-badge figcaption{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  color: #fff;
  /* background: rgb(var(--color-theme)); */
  padding: 7px 0px;
}

/* @media (min-height: 480px) {
  .Overlay-preview {
    display: block;
  }
} */

@keyframes Overlay-preview--appear {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes Overlay-column--appear {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (min-width: 600px) {
  .Overlay-preview {
    order: -1;
    /* width: 50%;
    max-width: 500px; */
    transform: translateY(calc(-100% - 2rem));
  }
}

@media (min-width: 600px) {
  .Overlay-preview {
    transform: translateY(calc(-100% - 3rem));
  }
}

.Overlay-thumbnail {
  /* width: var(--Overlay-preview-thumbnail-size); */
  /* background-color: rgb(var(--color-gray-light)); */
  background-color: rgb(var(--color-theme));
  width: 100%;
  height: 100%;
  max-width: 8.5rem;
  min-width: 6rem;
}

.Overlay-title {
  margin: 1.6em 0 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.Overlay-meta {
  margin-left: calc(var(--Overlay-preview-thumbnail-size) - 1.5rem);
  line-height: 1.25rem;
  font-size: 0.875rem;
  user-select: text;
}

.Overlay-meta:only-child {
  padding: 0;
}

.Overlay-raw {
  flex-shrink: 0;
  display: flex;
  width: 100%;
  max-width: 30ch;
  height: 3.5rem;
  border: 3px solid;
  border-color: rgb(var(--color-gray-light));
  padding: 0.875rem;
  margin: 1.5rem auto;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  background-color: #fff;
  opacity: 0;
  transform: translateY(100%);
  animation: Overlay-raw--appear 600ms 400ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes Overlay-raw--appear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.Overlay-url {
  flex-grow: 1;
  width: 100%;
  border: 0;
  color: rgb(var(--color-text-muted));
  background: none;
  -webkit-appearance: none;
  border-radius: 0;
  user-select: text;
  min-width: 0;
}

.Overlay-url:focus-visible {
  outline: 0 !important;
}

.Overlay-fade {
  position: relative;
  border-right: 1px solid rgb(var(--color-gray-light));
  pointer-events: none;
}

.Overlay-fade::after {
  content: "";
  height: 100%;
  width: 5em;
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: inset -1rem 0 1rem #fff;
}

.Overlay-button {
  display: inline-block;
  padding: 0 0.625rem;
  margin: -1rem -0.625rem 0 -1px;
  position: relative;
  color: rgb(var(--color-link));
  transition: transform 250ms var(--ease);
  height: 3.5rem;
  font-weight: 600;
}

.Overlay-button:hover {
  color: rgb(var(--color-link-shaded));
}

.Overlay-button.is-active {
  transform: translateY(3.5rem);
}

.Overlay-button::after {
  content: attr(data-oncopy);
  position: absolute;
  left: 50%;
  top: 0;
  height: 3.5rem;
  line-height: 3.5rem;
  text-indent: 0;
  color: rgb(var(--color-link));
  transform: translate(-50%, -3.5rem);
}

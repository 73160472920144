@import "../base";

/** @define Ticket */
.Ticket {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0.6rem 1.25rem 0;
  justify-content: space-between;
  background: rgb(var(--color-gray-light));
}

.Ticket-date {
  padding: 0.4rem 1.25rem;
  margin: 0.8rem 1.25rem 0 0;
  color: #fff;
  font-size: 3rem;
  background: rgb(var(--color-theme));
  background: rgb(var(--high-contrast-dark, var(--color-theme)));
}

.Ticket-main {
  display: flex;
  flex-wrap: wrap-reverse;
}

.Ticket-details {
  margin-top: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Ticket-time {
  font-size: 2rem;
}

.Ticket-location {
  margin-top: -0.4rem;
  font-weight: 600;
  font-size: 0.875rem;
}

.Ticket-info {
  margin: 1.5rem 0;
  font-size: 0.875rem;
}

.Ticket-action {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 1.1rem 0;
  position: relative;
  border-top: 1px solid rgb(228, 228, 228);
  font-weight: 600;
  color: rgb(var(--high-contrast-dark, 110, 110, 110));
  user-select: none;
  transition: color 50ms cubic-bezier(0,0,.2,1);
}

.Ticket-action:hover {
  color: rgb(var(--high-contrast-dark, var(--color-text-muted-shaded)));
}


@media (min-width: 700px) {
  .Ticket {
    padding: 1rem 1.5rem;
  }

  .Ticket-date {
    padding: 0.4rem 1.4rem 0;
    font-size: 4rem;
    white-space: nowrap;
    margin: 1.3rem 1.7rem 0 0;
  }

  .Ticket-time {
    white-space: nowrap;
    font-size: 3.5rem;
  }

  .Ticket-location {
    font-size: 1rem;
  }

  .Ticket-info {
    margin: 1.5rem 0 3rem;
    font-size: 1rem;
  }
}

@media (min-width: 1200px) {
  .Ticket {
    padding: 2.2rem 3rem 1rem;
  }

  .Ticket-date {
    font-size: 5rem;
  }
}

/* @define utilities */

@import "../global";

.u-loading,
.u-loadingAdaptive {
  --current-background: 0, 0, 0, 0.04 !important;
  background: rgba(var(--current-background)) !important;
  display: inline !important;
  color: transparent !important;
  user-select: none !important;
}

.u-loadingAdaptive {
  background: rgba(255, 255, 255, 0.2) !important;
}

/* @define Background11 */

.Background11 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.Background11-city {
  height: 20%;
  padding: 0 5vw;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 900px) {
  .Background11-city {
    width: auto;
    max-height: 23vh;
  }
}

.Background11--small .Background11-city {
  width: auto;
  left: 103%;
}

.Background11-detail {
  opacity: 0;
  transform: translateY(100%);
  animation: Background11-detail--appear 400ms 100ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.Background11-detail--shaded {
  fill: rgb(var(--color-goal-11-shaded));
}

@keyframes Background11-detail--appear {
  from {
    transform: translateY(100%);
    opacity: 1;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

/**
 * Train
 */

.Background11-train {
  height: 4%;
  width: auto;
  position: absolute;
  right: calc(100% + 1px);
  bottom: 0;
  will-change: transform;
  transition-delay: 500ms;
}

.Background11-train.is-coming {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

.Background11-train.is-leaving {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

@media (min-width: 900px) {
  .Background11-train {
    transition-delay: 0ms;
    max-height: 3.5vh;
  }
}

:root[scripting-enabled] .Background11-train.in-transition {
  transition-property: transform;
}

/**
 * Fountain
 */

.Background11-spray {
  stroke-dashoffset: 80;
  animation-name: Background11-spray--running;
  animation-duration: 2500ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

:root[scripting-enabled] .Background11-spray {
  animation-name: none;
}

/**
 * 1. I dunno, it just works 😎
 */

:root[scripting-enabled] .Background11-spray.is-starting {
  stroke-dasharray: 4 4 4 4 4 4 4 4 4 4 4 4 4 4 4 4 4 4 4 4 4 4 4 4 4 107; /* 1 */
  stroke-dashoffset: 302; /* 1 */
  animation-duration: 2500ms;
  animation-name: Background11-spray--starting;
  animation-iteration-count: 1;
}

@keyframes Background11-spray--starting {
  to {
    stroke-dashoffset: 198; /* 1 */
  }
}

:root[scripting-enabled] .Background11-spray.is-running {
  animation-name: Background11-spray--running;
}

@keyframes Background11-spray--running {
  to {
    stroke-dashoffset: 0;
  }
}

/**
 * Sky
 */

.Background11-sky {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  animation: Background11-sky--rise 900ms cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

.Background11--small .Background11-sky {
  top: 80%;
}

@keyframes Background11-sky--rise {
  to {
    transform: translateY(-100%);
  }
}

.Background11-sun {
  width: 86px;
  max-width: 10vw;
  position: absolute;
  left: 6vw;
  top: 48vh;
  visibility: hidden;
}

@media (min-width: 900px) {
  .Background11-sun {
    visibility: visible;
  }
}

.Background11--small .Background11-sun {
  display: none;
}

.Background11-lightRay {
  stroke-dasharray: 10 10;
  stroke-dashoffset: 30;
  animation: Background11-lightRay--appear 2500ms linear infinite forwards;
}

@keyframes Background11-lightRay--appear {
  0% {
    opacity: 0;
    stroke-dashoffset: 30;
  }

  15% {
    opacity: 1;
    stroke-dashoffset: 27;
  }

  85% {
    opacity: 1;
    stroke-dashoffset: 20;
  }

  100% {
    opacity: 0;
    stroke-dashoffset: 10;
  }
}

.Background11-cloud {
  height: auto;
  position: absolute;
  color: #fff;
  opacity: 0.2;
  animation: Background11-cloud--appear 1200ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

@keyframes Background11-cloud--appear {
  0% {
    transform: translateY(200%) translateY(50px);
  }

  25% {
    transform: translateY(200%);
  }

  100% {
    transform: translateY(0%);
  }
}

.Background11-cloud--sm {
  width: 2rem;
}

.Background11-cloud--md {
  width: 3rem;
}

.Background11-cloud--lg {
  width: 5rem;
}

.Background11-cloud--dark {
  opacity: 0.4;
  color: rgb(var(--color-goal-11-shaded));
}

.Background11-cloud:nth-child(1) {
  top: 60vh;
  left: -2.5%;
}

.Background11-cloud:nth-child(2) {
  top: 49vh;
  left: 5.55%;
}

.Background11-cloud:nth-child(3) {
  top: 28vh;
  right: 15%;
}

.Background11-cloud:nth-child(4) {
  top: 33vh;
  right: 14%;
}

.Background11-cloud:nth-child(5) {
  top: 50vh;
  right: -3%;
}

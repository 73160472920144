@import "../base";

/** @define Symbol */
.Popup {
  position: fixed;
  z-index: 10;

  bottom: 10px;
  right: 10px;

  width: 100%;
  max-width: calc(100vw - 20px);

  height: auto;

  padding: 3rem 2rem 2rem 2rem;

  background: rgb(var(--color-link));
  color: rgb(var(--current-background));

  box-shadow:
    2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);

  transform: translateY(calc(100% + 40px));

  transition: transform 240ms var(--ease-out);
}

.Popup.show {
  transform: translateY(0);
}

.Popup-closePopup {
  position: absolute;
  top: 20px;
  right: 20px;

  height: 24px;
  width: 24px;

  border-radius: 100%;

  background-color: rgb(var(--current-background));
  color: rgb(var(--color-link));

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  transition: background-color 180ms var(--ease-out);
}

.Popup-closePopup:hover {
  background-color: rgba(var(--current-background), .8)
}

.Popup-closePopup svg {
  height: 12px;
  width: 12px;
}

/* Formular */
.Popup-title {
  flex-basis: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: var(--heading-font-family);
  line-height: var(--heading-line-height);
  word-spacing: var(--heading-word-spacing);
  margin-bottom: 1rem;
}

.Popup-fields {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  margin: 1rem 0;
}

.Popup-label {
  flex: 1 1 100%;
  width: 100%;
  min-width: 0;
}

.Popup-field {
  width: 100%;
  padding: 0.6rem 0.7rem;
  border: 3px solid;
  border-color: rgb(var(--high-contrast-dark, var(--color-gray-light)));
  font-size: 1rem;
  background: rgb(var(--high-contrast-dark, 255, 255, 255));
  color: rgb(var(--high-contrast-light, var(--default-color)));
  -webkit-appearance: none;
  border-radius: 0;
  user-select: text;
}

.Popup-field::placeholder {
  color: rgb(var(--high-contrast-light, var(--color-text-muted)));
  opacity: 1;
}

.Popup-field:focus-visible {
  outline: 0 !important;
  border-color: rgb(var(--color-gray));
}

.Popup-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  margin: 0 auto;
}

.Popup-text {
  color: currentColor;
  font-family: var(--default-font-family);
  line-height: var(--default-line-height);
  font-size: 1rem;
  max-width: 39em;
  text-align: left;
}

.Popup-controls .note {
  text-align: center;
}

.Popup-controls .note a {
  text-decoration: underline;
}

.Popup-controls .note a:hover {
  text-decoration: none;
}

/* Text modifiers */
.Popup-text--muted {
  opacity: .8;
}

.Popup-text--small {
  font-size: 85%;
}

/* Above mobile */
@media (min-width: 768px) {
  .Popup {
    width: 40vw;
    min-width: 640px;

    padding: 3rem 4rem 3rem 3rem;

    bottom: 20px;
    right: 20px;

    max-width: calc(100vw - 40px);
  }

  .Popup-fields {
    flex-direction: row;
  }

  .Popup-label {
    flex: 1 1 50%;
    width: 50%;
  }

  .Popup-controls {
    flex-direction: row;
    margin: 0;
  }

  .Popup-controls .note {
    text-align: left;
  }
}

@import "../base";

/* @define Hero */
.Hero {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

@media print {
  .Hero {
    margin-bottom: 4rem;
  }
}

.Hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 730px;
  min-height: 60vh;
  position: relative;
  overflow: hidden;
}

@media (min-width: 600px) {
  .Hero-container {
    min-height: 76vh;
  }
}

.Hero.is-loading .Hero-container {
  background-image: var(--figure-gradient);
}

.Hero-figure {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.Hero-figure::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: var(--figure-gradient);
}

.Hero-image {
  object-fit: cover;
  width: 100%;
  min-height: 100%;
  height: auto;
  background-color: rgba(var(--color-gray));
}

@supports (object-fit: cover) {
  .Hero-image {
    height: 100%;
    min-width: 100%;
  }
}

.Hero-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 2rem 0;
  position: relative;
  align-items: center;
  text-align: center;
  transform: translateY(-10%);
}

/**
 * 1. Cast to rgba-comaptible value
 * 2. Safari requires the variable to be defined before being used
 */

.Hero-title,
.Hero-body {
  --rgba: var(--high-contrast-light), 1; /* 1 */
  --background-color: rgba(var(--rgba, 0, 0, 0, 0)); /* 2 */
  color: rgb(var(--high-contrast-dark, var(--current-background)));
  background-color: var(--background-color);
  box-shadow:
    0.5rem 0 0 var(--background-color),
    -0.5rem 0 0 var(--background-color);
}

.Hero-title {
  display: inline-block;
  padding: 0.09em 1rem 0;
  margin-bottom: 0.09em;
  font-size: 3rem;
  font-family: var(--heading-font-family);
  line-height: 1;
  word-spacing: var(--heading-word-spacing);
  text-transform: uppercase;
}

@media (min-width: 350px) {
  .Hero-title {
    font-size: 4rem;
  }
}

.Hero-body {
  max-width: 30em;
  padding: 0 1.5rem;
}

@media (min-width: 800px) {
  .Hero-title {
    font-size: 6rem;
    white-space: nowrap;
  }
}

@media (min-width: 1000px) {
  .Hero-title {
    font-size: 8.5rem;
    white-space: nowrap;
  }

  .Hero-body {
    max-width: 38rem;
    font-size: 1.1875rem;
  }
}

@media (min-width: 1600px) {
  .Hero-title {
    font-size: 10rem;
  }
}

.Hero-caption {
  display: block;
  margin: 0.5rem var(--page-gutter);
  max-width: 39em;
  font-size: 0.8em;
  line-height: var(--default-line-height);
  color: rgb(var(--color-text-muted));
}

@supports (margin-left: constant(safe-area-inset-left)) {
  .Hero-caption {
    margin-left: calc(var(--page-gutter)  + constant(safe-area-inset-left));
    margin-right: calc(var(--page-gutter) + constant(safe-area-inset-right));
  }
}

@supports (margin-left: env(safe-area-inset-left)) {
  .Hero-caption {
    margin-left: calc(var(--page-gutter) + env(safe-area-inset-left));
    margin-right: calc(var(--page-gutter) + env(safe-area-inset-right));
  }
}

@media (min-width: 1200px) {
  .Hero-caption {
    margin: 0.5rem 0 0;
  }
}

.Hero-icon {
  height: 8rem;
  width: auto;
  display: inline-block;
  margin: 4rem 0 2rem;
}

@media (min-width: 800px) {
  .Hero-icon {
    margin: 8rem 0 2rem;
  }
}

@media (min-width: 1000px) {
  .Hero-icon {
    margin: 8rem 0 2rem;
    height: 10.6rem;
  }
}

@import "../base";

/* @define Highlight */
.Highlight {
  --current-background: var(--color-theme);
  display: inline-block;
  margin: 0 0.2em;
}

/**
 * 1. Safari requires the variable to be defined before being used
 */

.Highlight-text {
  --background-color: rgb(var(--current-background)); /* 1 */
  display: inline;
  padding: 0.15em 0 0.1em;
  font-family: var(--heading-font-family);
  line-height: var(--heading-line-height);
  word-spacing: var(--heading-word-spacing);
  text-transform: uppercase;
  box-decoration-break: clone;
  background-color: rgb(var(--current-background));
  color: rgb(255, 255, 255);
  line-height: 1.1;
  box-shadow:
    -0.2em 0 0 var(--background-color),
    0.2em 0 0 var(--background-color);
}

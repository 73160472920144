/* @define Background10 */

.Background10 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(100%);
  transition: transform 1400ms 50ms cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform;
}

.Background10.is-visible {
  transform: translateY(0);
}

.Background10-bend {
  width: 200%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%) scaleY(1);
  background: rgb(var(--color-goal-10));
  border-radius: 100%;
  animation: Background10-bend--disappear 2200ms 300ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

@keyframes Background10-bend--disappear {
  to {
    transform: translateX(-50%) scaleY(0);
  }
}

.Background10-board {
  width: 200%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  transform-origin: 50% 0;
  transition: transform 2600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.Background10-board.is-rightTilted {
  transform: translateX(-50%) rotate(8deg);
}

.Background10-board.is-leftTilted {
  transform: translateX(-50%) rotate(-8deg);
}

.Background10-board--light {
  opacity: 0.15;
  background-color: #fff;
}

.Background10-board--dark {
  background-color: #bc015b;
}

@import "../global";

/* @define utilities */
.u-hidden {
  display: none !important;
}

.u-hiddenVisually {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

@media print {
  .u-printHidden {
    display: none !important;
  }
}

/**
 * Display
 */

.u-block { display: block !important; }
.u-inlineBlock { display: inline-block !important; }
.u-inline { display: inline !important; }
.u-flex { display: flex !important; }

/**
 * Flex
 */

.u-flexWrap { flex-Wrap: wrap !important; }
.u-alignCenter { align-items: center !important; }
.u-alignStart { align-items: flex-start !important; }
.u-alignEnd { align-items: flex-end !important; }
.u-justifyCenter { justify-content: center !important; }

/**
 * Positioning
 */

.u-posStatic { position: static !important; }
.u-posRelative { position: relative !important; }
.u-posAbsolute { position: absolute !important; }
.u-posFixed { position: fixed !important; }
.u-posSticky { position: sticky !important; }

.u-top { top: 0 !important; }
.u-right { right: 0 !important; }
.u-bottom { bottom: 0 !important; }
.u-left { left: 0 !important; }

/**
 * Aspect ratio
 */

.u-aspect1-1,
.u-aspect4-3,
.u-aspect16-9 {
  position: relative !important;
}

.u-aspect1-1::before,
.u-aspect4-3::before,
.u-aspect16-9::before {
  content: "" !important;
  display: block !important;
  height: 0 !important;
}

.u-aspect1-1::before { padding-top: 100% !important; }
.u-aspect4-3::before { padding-top: calc(100% * 3 / 4) !important; }
.u-aspect16-9::before { padding-top: calc(100% * 9 / 16) !important; }

/**
 * Cover offset parent
 */

.u-cover {
  object-fit: cover !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
}

/**
 * Columns
 */

.u-cols {
  display: flex !important;
  flex-wrap: wrap !important;
}

.u-col {
  flex: 0 0 100%;
  display: flex !important;
  min-width: 0 !important;
}

/**
 * Centered page container
 */

.u-container,
.u-md-container,
.u-lg-container,
.u-xl-container {
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: var(--page-gutter) !important;
  padding-right: var(--page-gutter) !important;
  max-width: calc(var(--max-width) + (var(--page-gutter) * 2)) !important
}

@supports (padding-left: constant(safe-area-inset-left)) {
  .u-container,
  .u-md-container,
  .u-lg-container,
  .u-xl-container {
    padding-left: calc(var(--page-gutter) + constant(safe-area-inset-left)) !important;
    padding-right: calc(var(--page-gutter) + constant(safe-area-inset-right)) !important;
  }
}

@supports (padding-left: env(safe-area-inset-left)) {
  .u-container,
  .u-md-container,
  .u-lg-container,
  .u-xl-container {
    padding-left: calc(var(--page-gutter) + env(safe-area-inset-left)) !important;
    padding-right: calc(var(--page-gutter) + env(safe-area-inset-right)) !important;
  }
}

@media (max-width: 599px) {
  .u-md-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 899px) {
  .u-lg-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 1199px) {
  .u-xl-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@import "../base";

/* @defind Embed */
.Embed {
  width: 100%;
  position: relative;
  color: rgb(var(--current-background));
  min-height: 6rem;
  background: rgba(0, 0, 0, 0.04);
}

.Embed-link {
  display: block;
  width: 0;
  height: 0;
  cursor: default;
}

.Embed-link::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.Embed-link::after {
  --current-color: 255, 255, 255, 0;
  content: "";
  width: 4rem;
  height: 4rem;

  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;

  text-indent: 3rem;
  white-space: nowrap;
  overflow: hidden;

  transform: translate(-50%, -50%);
  transition: transform 140ms var(--ease);
  will-change: transform;
  background-color: rgba(var(--current-color));
  background-image: url("data:image/svg+xml,%3Csvg width='120' height='120' viewBox='0 0 120 120' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle stroke='%23FFF' stroke-width='6' fill-opacity='.2' fill='%231A1A1A' cx='60' cy='60' r='57'/%3E%3Cpath fill='%23FFF' d='M47.5 42.5l30 18.1-30 18.1z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: 100% 100%;
}

.Embed:hover .Embed-link::after {
  transform: translate(-50%, -50%) scale(1.08);
}

.Embed--fullscreen::after,
.Embed--hidden::after {
  content: none;
}

@media (min-width: 600px) {
  .Embed-link::after {
    width: 5.5rem;
    height: 5.5rem;
    transform: translate(-50%, -60%);
  }

  .Embed:hover .Embed-link::after {
    transform: translate(-50%, -60%) scale(1.08);
  }
}

@media (min-width: 1000px) {
  .Embed-link::after {
    width: 7.5rem;
    height: 7.5rem;
  }
}

.Embed-image {
  display: block;
  width: 100%;
  height: auto;
}

.Embed-caption {
  display: none;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;
  padding: 1.4rem 2rem;
  position: absolute;
  bottom: 0;
  left: 0;

}

.Embed-caption::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.2) 46%, rgba(0, 0, 0, 0.5) 100%);
  opacity: var(--high-contrast-on, 0.5);
  transition: opacity 240ms var(--ease);
}

.Embed:hover .Embed-caption::before {
  opacity: 1;
  transition-duration: 160ms;
}

@media (min-width: 900px) {
  .Embed-caption {
    display: flex;
  }
}

/**
 * 1. Cast to rgba-comaptible value
 * 2. Safari requires the variable to be defined before being used
 */

.Embed-title,
.Embed-description {
  --rgba: var(--high-contrast-light), 1; /* 1 */
  --background-color: rgba(var(--rgba, 0, 0, 0, 0)); /* 2 */
  color: rgb(var(--high-contrast-dark, var(--current-background)));
  background-color: var(--background-color);
  box-shadow:
    0.35rem 0 0 var(--background-color),
    -0.35rem 0 0 var(--background-color);
}

.Embed-description {
  padding: 0.5rem 0 0;
}

.Embed-title {
  padding-top: 0.2rem;
  position: relative;
  z-index: 1;
  font-size: 1.5rem;
  font-family: var(--heading-font-family);
  line-height: var(--heading-line-height);
  word-spacing: var(--heading-word-spacing);
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .Embed-title {
    font-size: 2rem;
  }
}

/**
 * Fullscreen mode
 */

.Embed--fullscreen {
  --current-color: 0, 0, 0, 0.85;
  width: 100vw;
  height: 100vh;
  padding: 2rem;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;

  background: rgba(var(--current-color));
  animation: Embed--appear 250ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes Embed--appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Embed--fullscreen.is-closing {
  animation: Embed--disappear 350ms 200ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes Embed--disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.Embed-close {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
}

.Embed-cross {
  box-sizing: content-box;
  width: 1.5rem;
  height: 1.5rem;
  padding: 1rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  border-radius: 2px;
  pointer-events: none;
  background-color: rgba(81, 81, 81, 0);
  transition: background-color 180ms var(--ease-out);
}

.Embed-cross::before,
.Embed-cross::after {
  content: "";
  width: 1.5rem;
  height: 2px;
  position: absolute;
  right: 1rem;
  top: 50%;
  background: currentColor;
}

.Embed-cross::before {
  transform: rotate(45deg);
}

.Embed-cross::after {
  transform: rotate(-45deg);
}

.Embed-close:hover .Embed-cross {
  background-color: rgba(81, 81, 81, 1);
}

.Embed-wrapper {
  height: 0;
  width: 75%;
  max-width: var(--max-width);

  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
}

.Embed-iframe {
  height: 0;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding-bottom: calc(100% * (9 / 16));

  background-color: #000;
  box-shadow: 0 0 10rem rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);
  will-change: transform;
}

.Embed--fullscreen.is-closing .Embed-iframe {
  animation: Embed-iframe--disappear 250ms cubic-bezier(0.33, 0.89, 0.62, 1) forwards;
}

@keyframes Embed-iframe--disappear {
  from {
    transform: translate(-50%, -50%);
  }

  to {
    transform: translate(-50%, 50vh);
  }
}

.Embed-iframe iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.Embed--fullscreen .Embed-iframe iframe {
  visibility: hidden;
  animation: Embed-content--appear 0ms 650ms linear forwards;
}

@keyframes Embed-content--appear {
  to {
    visibility: visible;
  }
}

/* @define Background2 */

.Background2 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.Background2-field {
  width: 200%;
  height: auto;
  max-height: 330px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 600px) {
  .Background2:not(.Background2--small) .Background2-field {
    width: 100%;
  }
}

.Background2-crop {
  color: #fff;
  opacity: 0;
  animation: Background2-crop--appear 500ms 100ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.Background2-crop--carrot {
  display: none;
}

@media (min-width: 600px) {
  .Background2-crop--carrot {
    display: block;
  }
}

@keyframes Background2-crop--appear {
  from {
    opacity: 1;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

.Background2-crop--grow {
  transform-origin: center bottom;
  animation: Background2-crop--appear 600ms 200ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards, Background2-crop--growing 1000ms 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) forwards;
}

.Background2-crop--grow:nth-child(1) {
  animation-delay: 200ms, 1000ms;
}

.Background2-crop--grow:nth-child(2) {
  animation-delay: 200ms, 1800ms;
}

.Background2-crop--grow:nth-child(3) {
  animation-delay: 200ms, 1600ms;
}

.Background2-crop--grow:nth-child(4) {
  animation-delay: 200ms, 2200ms;
}

.Background2-crop--grow:nth-child(5) {
  animation-delay: 200ms, 1800ms;
}

.Background2-crop--grow:nth-child(6) {
  animation-delay: 200ms, 2600ms;
}

@keyframes Background2-crop--growing {
  to {
    transform: translateY(-30px);
  }
}

.Background2-crop--dark {
  color: rgb(var(--color-goal-2-shaded));
}

.Background2-tractor {
  width: auto;
  height: 7%;
  max-height: 111px;
  position: absolute;
  left: 12%;
  bottom: 0;
  transform: translateY(100%);
  animation: Background2-tractor--appear 400ms forwards var(--ease-out);
}

@keyframes Background2-tractor--appear {
  to {
    transform: translateY(0%);
  }
}

.Background2--small .Background2-tractor {
  display: none;
}

@media (min-width: 600px) {
  .Background2-tractor {
    left: 40%;
  }
}

@media (min-width: 1400px) {
  .Background2-tractor {
    left: 45%;
  }
}

.Background2-chassis {
  animation: Background2-chassis--shaking 725ms infinite linear;
}

@keyframes Background2-chassis--shaking {
  0% { transform: translate(1px, 0.5px) rotate(0deg); }
	10% { transform: translate(-0.5px, -1px) rotate(-1deg); }
	20% { transform: translate(-1.5px, 0px) rotate(1deg); }
	30% { transform: translate(0px, 1px) rotate(0deg); }
	40% { transform: translate(0.5px, -0.5px) rotate(1deg); }
	50% { transform: translate(-0.5px, 1px) rotate(-1deg); }
	60% { transform: translate(-1.5px, 0.5px) rotate(0deg); }
	70% { transform: translate(1px, 0.5px) rotate(-1deg); }
	80% { transform: translate(-0.5px, -0.5px) rotate(1deg); }
	90% { transform: translate(1px, 1px) rotate(0deg); }
	100% { transform: translate(0.5px, -1px) rotate(-1deg); }
}

@import "../base";

/** @define Subject */
.Subject {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50vh;
  padding: 3rem 0;
}

.Subject-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (min-width: 1000px) {
  .Subject-container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
  }
}

.Subject-body {
  max-width: 100%;
}

@media (min-width: 1000px) {
  .Subject-body {
    max-width: 40rem;
  }
}

.Subject-title {
  margin-top: -0.22em;
  font-size: 3rem;
  font-family: var(--heading-font-family) !important;
  line-height: var(--heading-line-height) !important;
  word-spacing: var(--heading-word-spacing) !important;
  text-transform: uppercase;
}

@media (max-width: 400px) {
  .Subject-title--xl {
    font-size: 2rem;
  }
}

@media (min-width: 1000px) {
  .Subject-title {
    font-size: 10rem;
  }

  .Subject-title--lg {
    font-size: 8rem;
  }

  .Subject-title--xl {
    font-size: 5rem;
  }
}

.Subject-image {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 8em;
  height: 8rem;
  margin-bottom: 2rem;
  position: relative;
}

@media (min-width: 1000px) {
  .Subject-image {
    align-items: flex-start;
    justify-content: flex-end;
    width: 15rem;
    height: 15rem;
    margin: 0 5rem 0 0;
  }
}

.Subject-img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.Subject-description {
  font-size: 1rem;
  max-width: 32em;
}

@media (min-width: 1000px) {
  .Subject-description {
    font-size: 1.25rem;
  }
}

@import "../base";

/* @define Banner */
.Banner-slot {
  display: flex;
  order: 1;
  background: rgb(var(--color-gray-light));
  outline: 0.2rem solid;
  outline-offset: -0.2rem;
  outline-color: rgba(var(--high-contrast-dark, 0, 0, 0, 0));
}

/**
 * 1. Fill extra space but allow Banner-slot to grow in when wrapped
 */

.Banner-figure {
  flex: 1 1 100%;
  overflow: hidden;
  position: relative;
  min-height: 65vh;
  background: rgb(215, 215, 215);
}

/**
 * 1. Maintain 16/9 aspect ratio in print
 */

@media print {
  .Banner-figure {
    padding-bottom: calc(100% * 9/16); /* 1 */
    min-height: 0;
  }
}

.Banner-image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  min-width: 100%;
}

.Banner-slot .Banner-figure::before {
  content: "";
  display: block;
  padding-bottom: 60%;
  background-color: rgba(var(--color-gray-light), 0.6);
}

@supports (object-fit: cover) {
  .Banner-image {
    top: 0;
    left: 0;
    min-width: 0;
    width: 100%;
    height: 100%;
    transform: none;
    object-fit: cover;
  }
}

.Banner-caption {
  display: block;
  margin: 0.5rem var(--page-gutter) 0;
  max-width: 50em;
  font-size: 0.8em;
  line-height: var(--default-line-height);
  color: rgb(var(--color-text-muted));
}

@supports (margin-left: constant(safe-area-inset-left)) {
  .Banner-caption {
    margin-left: calc(var(--page-gutter)  + constant(safe-area-inset-left));
    margin-right: calc(var(--page-gutter) + constant(safe-area-inset-right));
  }
}

@supports (margin-left: env(safe-area-inset-left)) {
  .Banner-caption {
    margin-left: calc(var(--page-gutter) + env(safe-area-inset-left));
    margin-right: calc(var(--page-gutter) + env(safe-area-inset-right));
  }
}

@media (min-width: 1200px) {
  .Banner-caption {
    margin: 0.5rem 0 0 0;
  }
}

/* @define Figure */

@import "../base";

.TargetGrid-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 -2rem;
}

.TargetGrid-cell {
  flex: 0 0 100%;
  min-width: 0;
  margin-bottom: 2rem;
}

@media (min-width: 600px) {
  .TargetGrid-container {
    width: calc(100% + 2rem);
    margin: 0 -1rem;
  }

  .TargetGrid-cell {
    margin: 0;
  }
}

@media (min-width: 800px) {
  .TargetGrid-cell {
    width: 50%;
    flex-basis: 50%;
  }
}

@media (min-width: 900px) {
  .TargetGrid-container {
    width: calc(100% + 3rem);
    margin: 0 -1.5rem;
  }
}

.Target-button.hidden, .Target-fade.hidden{
  display: none;
}

.expandAllButton {
  background: rgb(var(--color-gray-light));
  padding: 0.5rem 1.6rem;
  margin-bottom: 12px;
  border-radius: 42px;
  position: relative;
  top: auto;
  right: auto;
  cursor: pointer;
  display: none;
}

.expandAllButton:hover {
  background: rgb(200, 200, 200);
}

@media (min-width: 600px) {
  .expandAllButton {
    display: block;
  }
}
/* @define Background1 */

.Background1 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.Background1-people {
  width: 100%;
  max-width: 840px;
  max-height: 18vh;

  padding: 0 30px;
  position: absolute;
  bottom: 0;
  left: 50%;

  transform: translateX(-50%);
}

@media (max-width: 600px) {
  .Background1-people {
    width: 200vw;
    padding: 0;
    left: 110%;
  }
}

.Background1--small .Background1-people {
  width: 150%;
  max-width: none;
  max-height: none;
  left: 110.1%;
  bottom: 39%;
}

.Background1-person {
  fill: #fff;
  opacity: 0;
  transform: translateY(100%);
  animation: Background1-person--appearBottom 1000ms 50ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.Background1--small .Background1-person:not(.Background1-person--double) {
  transform: translateX(100%);
  animation-name: Background1-person--appearRight;
  animation-duration: 1550ms;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.275, 1);
}

@keyframes Background1-person--appearBottom {
  from {
    opacity: 1;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes Background1-person--appearRight {
  from {
    opacity: 1;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.Background1-person--striped {
  fill: rgb(var(--color-goal-1-shaded));
}

.Background1-stripes {
  animation: Background1-stripes--pan 3.9s linear;
}

@keyframes Background1-stripes--pan {
  to {
    transform: translateX(-25%);
  }
}

.Background1-person--double {
  transform: translateY(0);
  animation: Background1-person--show 600ms linear forwards;
}

@keyframes Background1-person--show {
  to {
    opacity: 1;
  }
}

.Background1-person--double:nth-child(1) {
  animation-delay: 3.3s;
}

.Background1-person--double:nth-child(2) {
  animation-delay: 2.2s;
}

.Background1-person--double:nth-child(3) {
  animation-delay: 3.1s;
}

.Background1-person--double:nth-child(4) {
  animation-delay: 2.9s;
}

.Background1-person--double:nth-child(5) {
  animation-delay: 2.7s;
}

.Background1-person--double:nth-child(6) {
  animation-delay: 1.5s;
}

.Background1-stripe {
  fill: rgb(var(--color-goal-1));
}

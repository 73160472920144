/* @define Background12 */

.Background12 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  color: rgb(var(--color-goal-12-shaded));
  animation: Background12-bg--appear 600ms 50ms var(--ease) forwards;
}

@keyframes Background12-bg--appear {
  to {
    opacity: 1;
  }
}

.Background12-circleBox {
  opacity: 0.4;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(0.85);
  animation: Background12-circle--appear 700ms 50ms var(--ease-out) forwards;
  will-change: transform;
}

@keyframes Background12-circle--appear {
  to {
    transform: scale(1);
  }
}

.Background12-circle {
  height: 95%;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0.6;
  transform: translate(-50%, -50%) rotate(0.5turn);
  will-change: transform;
  animation: Background12-circle--spin 30000ms linear infinite;
}

@keyframes Background12-circle--spin {
  from {
    transform: translate(-50%, -50%) rotate(0.5turn);
  }

  to {
    transform: translate(-50%, -50%) rotate(1.5turn);
  }
}

@media (min-width: 900px) {
  .Background12-circle {
    height: auto;
    width: 80%;
  }
}

.Background12--small .Background12-circle {
  height: auto;
  width: 100%;
  left: 100%;
}

@import "../base";

/** @define Calendar */
.Calendar {
  display: block;
  margin: 0;
  padding: 0;
}

.Calendar-item {
  list-style: none;
  display: block;
  position: relative;
}

.Calendar-item--firstOfDay:not(:first-child) {
  top: -1px;
  border-top: 1px solid rgba(var(--color-gray-light), 0.6);
}

.Calendar-item:first-child {
  padding-top: 0;
}

.Calendar-item--appear {
  opacity: 0;
  transform: translateY(100px);
  animation: Calendar-item--appear 600ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

@keyframes Calendar-item--appear {
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.Calendar-month {
  display: block;
  margin: 2.5rem 0 0.5rem;
  font-size: 2rem;
  font-family: var(--heading-font-family);
  line-height: var(--heading-line-height);
  word-spacing: var(--heading-word-spacing);
  text-transform: uppercase;
}

@media (min-width: 600px) {
  .Calendar-month {
    margin-top: 3rem;
  }
}

.Calendar-item:first-child .Calendar-month {
  margin-top: 0;
}

.Calendar-content {
  display: flex;
  flex-wrap: nowrap;
  min-height: 4rem;
  padding: 1rem 0 0;
  position: relative;
  z-index: 0;
}

@media (min-width: 600px) {
  .Calendar-content {
    padding: 2rem 0;
  }
}

.Calendar-item:not(.Calendar-item--firstOfDay) .Calendar-content {
  padding-left: 3.5rem;
}

@media (min-width: 600px) {
  .Calendar-item:not(.Calendar-item--firstOfDay) .Calendar-content {
    padding-left: 5.5rem;
  }
}

.Calendar-content:not(:last-child) {
  border-bottom: 1px solid;
  border-bottom-color: rgba(var(--color-gray-light), 0.6);
}

.Calendar-body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.Calendar-datetime {
  display: block;
  line-height: 1.4;
  font-size: 0.8rem;
  color: rgb(var(--high-contrast-dark, var(--color-text-muted)));
}

@media (min-width: 600px) {
  .Calendar-datetime {
    font-size: 1rem;
  }
}

.Calendar-date {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  float: left;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
  position: relative;
  left: 0;
  top: 0.1rem;
  border-radius: 100%;
  font-size: 1.25rem;
  color: #fff;
  background: rgb(var(--color-theme));
  background: rgb(var(--high-contrast-dark, var(--color-theme)));
  font-family: var(--heading-font-family);
  line-height: 2.1;
}

@media (min-width: 600px) {
  .Calendar-date {
    width: 4rem;
    height: 4rem;
    margin-right: 1.5rem;
    top: -0.3rem;
    font-size: 2rem;
  }
}

.Calendar-item.is-loading .Calendar-date {
  background: rgba(var(--color-gray-light), 0.6);
}

.Calendar-title {
  font-size: 0.875rem;
}

@media (min-width: 600px) {
  .Calendar-title {
    font-size: 1.25rem;
  }
}

.Calendar-link {
  display: block;
  flex: 1 1 auto;
  margin-right: 1rem;
}

.Calendar-link::before {
  content: "";
  width: calc(100% + 2em);
  height: calc(100% + 1px);
  position: absolute;
  left: -1em;
  top: 0;
  z-index: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.02);
  transition: opacity 175ms var(--ease-out);
  will-change: opacity;
}

.Calendar-link:first-child:hover::before,
.Calendar-download:not(:hover) + .Calendar-link:hover::before {
  opacity: 1;
}

.Calendar-download {
  order: 1;
  display: flex;
  align-items: center;
  padding: 0.7em 0.7em 0.6em 0.7em;
  margin-top: 0.5em;
  position: relative;
  z-index: 1;
  left: -0.7em;
  top: -0.3rem;
  font-size: 0.8rem;
  color: rgb(var(--high-contrast-light, var(--color-text-muted)));
  border-radius: 2px;
}

/**
 * 1. Compensate for normalize removing link backgrounds on hover
 */

.Calendar-download,
.Calendar-download:hover /* 1 */ {
  --background: var(--high-contrast-dark), 1;
  background-color: rgba(var(--background, 255, 255, 255, 0));
}

.Calendar-download::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
  background-color: rgba(var(--background, 0, 0, 0, 0.02));
  transition: opacity 175ms var(--ease-out);
  will-change: opacity;
}

.Calendar-download:hover::before {
  opacity: 1;
}

@media (min-width: 600px) {
  .Calendar-download {
    font-size: 1rem;
  }
}

@import "../base";

:root {
  --Telegram-height: 30vh;
}

/* @define Telegram */
.Telegram {
  position: relative;
  overflow: hidden;
  padding-bottom: var(--Telegram-height);
}

.Telegram::after {
  content: "";
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 1));
}

.Telegram-heading {
  font-weight: 600;
  font-size: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(var(--color-gray-light));
  color: rgb(var(--color-gray));
}

@media (min-width: 1200px) {
  .Telegram-heading {
    font-size: 1.25rem;
  }
}

.Telegram-list {
  overflow: hidden;
  position: absolute;
}

.Telegram-item {
  padding: 0.75rem 0;
  font-size: 1.125rem;
  will-change: opacity, transform;
  transition:
    transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 450ms 50ms cubic-bezier(0.19, 1, 0.22, 1);
}

.Telegram-item.is-disappearing {
  opacity: 0;
  transform: translateY(-2rem);
}

.Telegram-item.is-appearing,
.Telegram-item.is-appearing ~ .Telegram-item {
  transform: translateY(calc(var(--Telegram-void) * -1));
}

.Telegram-label {
  padding-top: 0.1em;
  will-change: opacity, transform;
  transition:
    transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 325ms cubic-bezier(0.19, 1, 0.22, 1);
}

.Telegram-label.is-disappearing {
  opacity: 0;
  transform: translateX(-1.5rem);
}

.Telegram-date {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 0.5rem;
  font-size: 1.5rem;
  color: rgb(var(--color-theme));
  font-family: var(--heading-font-family);
  line-height: var(--heading-line-height);
  word-spacing: var(--heading-word-spacing);
  text-transform: uppercase;
}

@media (min-width: 900px) {
  .Telegram-date {
    font-size: 2rem;
  }
}

/**
 * 1. Cover visible hole in Safari
 * 2. Prevent flash of background color during transition
 */

.Telegram-date::before {
  display: inline-block;
  content: "";
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.75rem;
  border: 0.875rem solid currentColor;
  background-color: currentColor; /* 1 */
  border-radius: 100%;
  transition:
    background-color 0ms 200ms, /* 2 */
    border-width 200ms cubic-bezier(0.19, 1, 0.22, 1);
  will-change: border-radius;
}

.Telegram.is-loading .Telegram-date {
  color: rgba(0, 0, 0, 0.04);
}

.Telegram.is-paused .Telegram-date::before {
  transition: border-width 200ms cubic-bezier(0.19, 1, 0.22, 1);
  background-color: transparent;
  border: 0.1875rem solid currentColor;
}

.Telegram-title {
  margin-bottom: 0.25em;
  font-weight: 600;
}

.Telegram-cite {
  display: block;
  margin-top: 0.25em;
  font-size: 0.8rem;
  font-style: normal;
  color: rgb(var(--color-text-muted));
}

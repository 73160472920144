@import "../base";

/** @define Thumbnail */
.Thumbnail {
  display: flex;
  width: 100%;
  position: relative;
  z-index: 0;
}

.Thumbnail::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.Thumbnail::after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background: rgb(var(--color-gray-light));
  transform-origin: center;
  transition: transform 195ms cubic-bezier(0.16, 1, 0.3, 1);
}

.Thumbnail--linked:hover::after {
  transform: scale(1.08);
}

.Thumbnail-container {
  flex: 1 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.Thumbnail-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

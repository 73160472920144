@import "../base";

/* @define Flag */
.Flag {
  display: flex;
  align-items: center;
  text-align: right;
  line-height: 1;
}

.Flag--reverse {
  flex-direction: row-reverse;
  text-align: left;
}

.Flag-symbol {
  flex: 0 0 2.5rem;
  width: 2.5rem;
  height: 1.66rem;
  margin-left: 0.9rem;
  order: 1;
  position: relative;
  overflow: hidden;
  transition: transform 150ms var(--ease);
}

.Flag--reverse .Flag-symbol {
  margin-left: 0;
  margin-right: 0.9rem;
}

.Flag-symbol > * {
  display: block;
  width: 100%;
  height: 100%;
}

/**
 * Pseudo element acting like a inner shadow
 */

.Flag-symbol::after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 0;
  left: 0;
}

.Flag--adapt path {
  fill: currentColor;
}

.Flag--adapt .Flag-symbol::after {
  border: 0;
}

.Flag-text {
  flex: 1 1 auto;
  transition: transform 150ms var(--ease);
}

.Flag-title {
  display: block;
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration: var(--high-contrast-underline, none);
}

.Flag-sub {
  font-size: 0.6875rem;
}

.Flag--vertical {
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.Flag--vertical .Flag-symbol {
  flex: 0 0 auto;
  order: 0;
  margin: 0 0 0.5rem;
}

.Flag:hover .Flag-symbol {
  transform: scale(1.1);
  transform-origin: center center;
}

.Flag:hover .Flag-text {
  transform: translateX(-0.15rem);
}

.Flag--reverse:hover .Flag-text {
  transform: translateX(0.15rem);
}

.Flag--vertical:hover .Flag-text {
  transform: translateY(0.15rem);
}

@import "../base";

/** @define Event */
.Event {
  width: 100%;
}

.Event--banner {
  min-height: 65vh;
}

.Event-content {
  height: 100%;
  position: relative;
  overflow: hidden;
  color: rgb(var(--current-background));
  background-color: rgb(var(--color-theme));
}

.Event:not(.Event--banner) .Event-content::before {
  content: "";
  display: block;
  height: 0;
  padding-top: calc(100% * 3 / 4);
}

.Event-icon {
  width: 3.3rem;
  height: 3.3rem;
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate(-50%, -50%);
}

@media (min-width: 350px) {
  .Event-icon {
    width: 4rem;
    height: 4rem;
  }
}

@media (min-width: 500px) {
  .Event--banner .Event-icon {
    width: 8rem;
    height: 8rem;
  }
}


.Event-date {
  position: absolute;
  left: 5%;
  bottom: 0.8rem;
  font-size: 3rem;
  line-height: 1;
  text-transform: uppercase;
  font-family: var(--heading-font-family);
  word-spacing: var(--heading-word-spacing);
}

.Event-details {
  max-width: calc(100% - 9rem);
  position: absolute;
  right: 5%;
  bottom: 1.1rem;
  text-align: right;
  white-space: nowrap;
}

.Event-time {
  display: block;
  font-weight: 600;
  letter-spacing: -0.01em;
}

@media (min-width: 350px) {
  .Event-details {
    max-width: calc(100% - 11rem);
    bottom: 1.25rem;
  }

  .Event-time {
    font-size: 1.75rem;
    line-height: 1;
    text-transform: uppercase;
    font-family: var(--heading-font-family);
    word-spacing: var(--heading-word-spacing);
    letter-spacing: 0;
  }

  .Event-date {
    font-size: 3.5rem;
  }
}

.Event-location {
  display: block;
  line-height: 1.2;
}

/**
 * Background
 */

.Event-shape {
  position: absolute;
  will-change: transform;
  transition: transform 600ms var(--ease-out);
}

.Event:not(.Event--static):not(.is-loading):hover .Event-shape {
  transition: transform 400ms var(--ease-out);
}

.Event-shape::before {
  content: "";
  display: block;
  height: 0;
  padding-top: 100%;
}

.Event-shape--big {
  width: 100%;
  left: -75%;
  top: 25%;
  transform: rotate(-20deg);
  transform-origin: 100% 0;
  background-color: rgb(197, 25, 45);
}

.Event:not(.Event--static):not(.is-loading):hover .Event-shape--big {
  transform: translateX(-2%) rotate(-10deg);
}

.Event-shape--small {
  width: 42%;
  right: -35%;
  top: 30%;
  transform: rotate(40deg);
  transform-origin: 0 0;
  background-color: rgb(197, 25, 45);
}

.Event:not(.Event--static):not(.is-loading):hover .Event-shape--small {
  transform: translateX(2%) rotate(25deg);
}

.Event-shape--circle {
  width: 30%;
  right: -6%;
  top: 10%;
  border-radius: 100%;
  transform-origin: 50% 50%;
  background-color: rgb(255, 58, 33);
}

.Event:not(.Event--static):not(.is-loading):hover .Event-shape--circle {
  transform: translate(5%, -15%);
}

.Event-figure::before,
.Event-figure::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: var(--figure-gradient);
  opacity: 0.8;
  transition: opacity 180ms var(--ease-out);
}

.Event:hover .Event-figure::after {
  opacity: 1;
}

.Event-figure::before {
  height: 50%;
  z-index: 1;
  background: rgb(var(--default-color));
  opacity: var(--high-contrast-on, 0);
}

.Event-meta {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Event-image {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
}

@supports (object-fit: cover) {
  .Event-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: none;
  }
}

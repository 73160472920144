@import "../base";

/** @define Form */
.Form {}

.Form-error {
  padding: 2rem;
  border: 2px solid rgb(var(--color-goal-1));
  position: relative;
  overflow: hidden;
}

.Form-error::after {
  content: "";
  width: 15vw;
  height: 15vw;
  max-width: 4rem;
  max-height: 4rem;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%) rotate(45deg);
  background-color: rgb(var(--color-goal-1));
}

.Form-field {
  display: block;
  margin: 0 0 1.5em;
  font-size: 1rem;
  position: relative;
}

.Form-field--plain {
  margin: 0;
}

.Form-label {
  display: block;
  margin-bottom: 0.8em;
  font-size: 0.9rem;
  font-weight: var(--high-contrast-bold, normal);
}

.Form-field--plain .Form-label {
  margin: 0;
}

@media (min-width: 1000px) {
  .Form-field--large .Form-label {
    font-size: 1.1875rem;
  }
}

.Form-meta {
  font-size: 0.9rem;
  font-style: italic;
  color: rgb(var(--color-text-muted));
}

.Form-control {
  display: block;
  width: 100%;
  font-size: 1rem;
  min-width: 0;
  padding: 0.6rem 0.7rem;
  border: 3px solid;
  border-color: rgba(var(--high-contrast-dark, var(--color-gray), 0.2));
  background: rgb(var(--high-contrast-dark, 255, 255, 255));
  color: rgb(var(--high-contrast-light, var(--default-color)));
  -webkit-appearance: none;
  border-radius: 0;
  user-select: text;
}

.Form-control--textarea:not([rows]) {
  height: 8.95rem;
}

.Form-control--textarea {
  resize: none;
}

.Form-control::placeholder {
  color: rgb(var(--high-contrast-light, var(--color-text-muted)));
  opacity: 1;
}

.Form-control:focus-visible {
  outline: 0 !important;
  border-color: rgb(var(--color-gray));
}

@media (min-width: 1000px) {
  .Form-field--large .Form-control {
    padding: 0.8rem 1rem;
    font-size: 1.1875rem;
  }
}

/**
 * Select
 */

.Form-control--select {
  padding-right: 2rem;
  user-select: none;
}

.Form-chevron {
  font-size: 0.8rem;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  pointer-events: none;
}

/**
 * File upload
 */

.Form-control--file {
  border: 0;
  padding: 0;
}

.Form-control--file::-webkit-file-upload-button {
  display: inline-block;
  line-height: 3.25rem;
  padding: 0 1.2rem;
  min-width: 10rem;
  border: solid #fff;
  border-width: 0 0.5em 0 0;
  border-radius: 0;
  outline: none;
  font-size: 1.5rem;
  color: rgb(var(--default-color));
  background-color: rgba(var(--color-gray), 0.12);
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  font-family: var(--heading-font-family);
  word-spacing: var(--heading-word-spacing);
  transition: 140ms var(--ease-out);
  transition-property: background-color, color;
}

.Form-control--file:not([disabled]):hover::-webkit-file-upload-button {
  background-color: rgba(var(--color-gray), 0.22);
}

.Form-control--file[disabled]::-webkit-file-upload-button {
  opacity: 0.6;
  cursor: not-allowed;
}

.Form-control--file::-ms-browse {
  display: inline-block;
  line-height: 3.25rem;
  padding: 0 1.2rem;
  min-width: 10rem;
  border: solid #fff;
  border-width: 0 0.5em 0 0;
  border-radius: 0;
  outline: none;
  font-size: 1.5rem;
  color: rgb(var(--default-color));
  background-color: rgba(var(--color-gray), 0.12);
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
  user-select: none;
  font-family: var(--heading-font-family);
  word-spacing: var(--heading-word-spacing);
  transition: 140ms var(--ease-out);
  transition-property: background-color, color;
}

.Form-control--file:not([disabled]):hover::-ms-browse {
  background-color: rgba(var(--color-gray), 0.22);
}

.Form-control--file[disabled]::-ms-browse {
  opacity: 0.6;
  cursor: not-allowed;
}

/**
 * Choice w/ description
 */

.Form-choice {
  --current-background: var(--color-theme);
  display: flex;
  flex-direction: column;
  padding: 2px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  background: rgb(var(--current-background));
  color: #fff;
}

.Form-heading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.Form-check {
  display: inline-block;
  border: 3px solid #fff;
  margin-left: 1rem;
  position: relative;
  top: -0.2em;
}

.Form-choice .Form-label {
  margin: 1rem;
  font-family: var(--heading-font-family) !important;
  line-height: var(--heading-line-height) !important;
  word-spacing: var(--heading-word-spacing) !important;
  text-transform: uppercase;
  font-size: 2rem;
  color: inherit;
}

.Form-choice .Form-label::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.Form-choice.is-unselected .Form-label::before,
:root:not([scripting-enabled]) .Form-choice .Form-check:not(:checked) + .Form-label::before {
  background: rgb(var(--color-gray));
}

.Form-description {
  flex: 1 0 auto;
  padding: 1rem calc(1rem + 4px);
  margin: 0 -2px;
  color: inherit;
  border-top: 2px solid #fff;
  font-weight: 500;
}

.Form .hidden-area{
  display: none;
  visibility: hidden;
  height: 0;
}
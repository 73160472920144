@import "../base";

/** @define Map */
.Map {
  display: flex;
  width: 100%;
  height: 50vh;
  max-height: 43rem;
  position: relative;
  background-color: rgb(var(--color-gray-light));
}

:root:not([scripting-enabled]) .Map {
  display: none;
}

@media (pointer: coarse) {
  .Map {
    display: none;
  }
}

@media (min-width: 600px) {
  .Map {
    height: 75vh;
  }
}

.Map.has-error {
  height: auto;
  max-height: none;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.Map-marker {
  cursor: pointer;
  color: rgb(var(--current-color));
  transition: transform 150ms cubic-bezier(0.19, 1, 0.22, 1);
}

.Map-marker--static {
  cursor: inherit;
}

.Map-marker.is-selected {
  transform-origin: 50% 100%;
  transform: scale(1.2);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.Map-popup {
  max-width: 16rem;
  min-width: 13rem;
  padding: 1.2rem 1.25rem 0.75rem;
  position: relative;
}

.Map-label {
  display: inline-block;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
}

.Map-heading {
  display: block;
  font-size: 1.5rem;
  font-family: var(--heading-font-family);
  line-height: var(--heading-line-height);
  word-spacing: var(--heading-word-spacing);
  text-transform: uppercase;
}

.Map-subheading {
  font-size: 0.75rem;
  color: rgb(var(--color-text-muted));
  margin: 0.2rem 0 0.3rem;
  display: block;
}

.Map-link {
  display: flex;
  justify-content: space-between;
  padding-top: 0.5em;
  margin-top: 0.5em;
  border-top: 1px solid rgb(var(--color-gray-light));
  font-size: 1.25rem;
  font-family: var(--heading-font-family);
  line-height: var(--heading-line-height);
  word-spacing: var(--heading-word-spacing);
  text-transform: uppercase;
  color: rgb(var(--color-link));
}

.Map-symbol {
  display: inline-block;
  margin-top: 0em;
  font-size: 0.9rem;
}

.mapboxgl-popup {
  max-width: none;
}

.mapboxgl-popup-content {
   padding: 0 !important;
   border-radius: 0 !important;
}

.mapboxgl-popup-content {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12) !important;
}

.mapboxgl-ctrl-group {
  border-radius: 0 !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12) !important;
}

.mapboxgl-ctrl-top-left,
.mapboxgl-ctrl-top-right,
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  z-index: 1 !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl > button {
  transition: background-color 90ms var(--ease-out);
}

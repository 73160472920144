@import "../../base";

/** @define MaterialGoal */
.MaterialGoal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background: rgb(var(--color-gray-light));
}

.MaterialGoal--grow {
  flex-wrap: wrap;
  flex-direction: row;
}

@media (min-width: 800px) {
  .MaterialGoal--grow {
    flex-wrap: nowrap;
    padding: 2rem;
  }
}

@media (min-width: 1200px) {
  .MaterialGoal--grow {
    padding: 3rem;
  }
}

.MaterialGoal--banner {
  padding: 0;
  align-items: stretch;
}

/**
 * Image
 */

.MaterialGoal-number {
  flex: 0 0 auto;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  min-height: auto;
  aspect-ratio: 4 / 3;
  
}
.MaterialGoal-number h2{
    margin: 0;
    display: flex;
    justify-content: center;
    font-size: 11rem;
}

@media (min-width: 800px) {
  .MaterialGoal--grow .MaterialGoal-figure {
    width: calc(100% * 1 / 3);
    margin-right: 2rem;
    margin-right: 3rem;
  }

  .MaterialGoal--grow .MaterialGoal-figure::before {
    padding-top: 80%;
  }

  .MaterialGoal--banner .MaterialGoal-figure {
    width: 70%;
    margin-right: 0;
  }
}

.MaterialGoal-image {
  width: 100%;
}

.MaterialGoal--banner .MaterialGoal-image {
  position: static;
  object-fit: cover;
}

@media (min-width: 800px) {
  .MaterialGoal--banner .MaterialGoal-image {
    position: absolute;
    object-fit: contain;
    height: 100%;
    background: #1a1a1a;
  }
}

/**
 * Body
 */

.MaterialGoal-body {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
}

@media (min-width: 800px) {
  .MaterialGoal--grow .MaterialGoal-body {
    display: block;
    padding: 0;
  }
}

.MaterialGoal--banner .MaterialGoal-body {
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
}

.MaterialGoal-title {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-family: var(--heading-font-family);
  line-height: var(--heading-line-height);
  word-spacing: var(--heading-word-spacing);
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .MaterialGoal--grow .MaterialGoal-title {
    font-size: 3rem;
    line-height: 1.05;
  }
}

.MaterialGoal:not(.MaterialGoal--grow) .MaterialGoal-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

/**
 * Link
 */

.MaterialGoal-action {
  order: 99;
  align-self: flex-start;
  margin-top: auto;
}

@media (min-width: 800px) {
  .MaterialGoal--grow .MaterialGoal-action {
    float: right;
    margin: 0 0 1.5rem 1.5rem;
  }
}

.MaterialGoal--banner .MaterialGoal-action {
  float: none;
  width: 100%;
  margin: 1.25rem 0 0;
}

@media (min-width: 800px) {
  .MaterialGoal--banner .MaterialGoal-action {
    margin-top: 2rem;
  }
}

.MaterialGoal-label {
  display: flex;
  align-items: center;
  width: 100%;
}

@media (min-width: 1200px) {
  .MaterialGoal--banner .MaterialGoal-label {
    padding: 0.4rem 0 0.2rem;
    font-size: 2rem;
  }
}

/**
 * Footer
 */

.MaterialGoal-footer {
  display: flex;
  flex-wrap: wrap;
  margin: 1.25rem -0.625rem 0.625rem -0.625rem;
  font-size: 0.75rem;
}

.MaterialGoal--banner .MaterialGoal-footer {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 800px) {
  .MaterialGoal--grow .MaterialGoal-footer {
    padding-top: 1.25rem;
    border-top: 1px solid rgba(var(--color-gray), 0.25);
    margin-bottom: -0.625rem;
    font-size: 0.875rem;
  }

  .MaterialGoal--banner .MaterialGoal-footer {
    order: -1;
    flex-direction: column;
    padding: 0;
    border: 0;
    margin: 0;
  }
}

.MaterialGoal-section {
  width: 50%;
  border: 0px solid transparent;
  border-width: 0 0.625rem;
  margin-bottom: 0.625rem;
}

@media (min-width: 1200px) {
  .MaterialGoal--grow .MaterialGoal-section {
    width: 25%;
  }
}

@media (min-width: 800px) {
  .MaterialGoal--banner .MaterialGoal-section {
    width: 100%;
    border: 0;
    margin-bottom: 1.25rem;
  }
}

.MaterialGoal-heading {
  display: block;
  font-weight: 600;
  font-size: 0.875rem;
}

@media (min-width: 1200px) {
  .MaterialGoal--banner .MaterialGoal-heading {
    margin-bottom: 0;
    font-size: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .MaterialGoal--grow .MaterialGoal-heading {
    margin-bottom: 0.625rem;
    font-size: 1.1875rem;
  }
}

.MaterialGoal-listItem {
  display: inline-block;
  margin: 0 0.25em 0 0;
}

.MaterialGoal-listItem:not(:last-child)::after {
  content: ",";
}

.MaterialGoal-link:hover {
  text-decoration: underline;
}

/**
 * Goal
 */

.MaterialGoal-allGoals {
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
}

.MaterialGoal-allGoals svg {
  width: 2rem;
  height: 2rem;
}

.MaterialGoal:not(.MaterialGoal--grow) .MaterialGoal-allGoals svg {
  width: 1.5rem;
  height: 1.5rem;
}

@import "../base";

/** @define ShareButton */
.ShareButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0.25em 0;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.ShareButton-icon {
  --current-color: var(--current-background);
  display: inline-block;
  margin-right: 1.5ch;
}

.ShareButton-text {
  display: inline-block;
  color: rgb(var(--high-contrast-dark, var(--color-text-muted)));
  transition: color 50ms cubic-bezier(0,0,.2,1);
}

.ShareButton:hover .ShareButton-text {
  color: rgb(var(--high-contrast-dark, var(--color-text-muted-shaded)));
}
